.header {
    @extend %grid-base;
    padding: 3rem 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 10;

    .container {
        @extend %flex-start-between;
    }

    &__logo {
        display: block;
        img {
            width: 13.6rem;
            height: 15rem;
        }

        &--sticky { display: none; }
    }

    &__actions {
        @extend %flex-align-center;
        gap: 3.5rem;
    }

    &__button {
        @extend %button;
        background-color: #fff;
        color: var(--color-primary);

        &:hover, &:focus-within {
            background-color: #000;
            border-color: #000;
            color: #fff;
        }
    }
}
.header.is-sticky {
    background-color: var(--color-tertiary);
    padding: 1rem 0;
    position: fixed;
    animation: fadeBottomSmall $transition ease-in-out forwards;


    .container {
        align-items: center;
    }

    .header__logo {
        display: none;
        &--sticky { 
            display: block;
        }
        &--sticky img {
            height: 8rem;
            width: 8rem;
        } 
    }
}