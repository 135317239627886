.quote {
    background-color: var(--color-secondary);
    padding: 13rem 7rem 18rem 7rem;
    position: relative;
    margin-bottom: 10rem;
    margin-top: 5rem;

    &::before, &::after {
        content: "";
        background: url('/storage/svg/african-line-overlay-1-white.svg') center center no-repeat;
        background-size: cover;
        opacity: .2;
        position: absolute;
        left: 0;
        height: 6.8rem;
        width: 100%;
    }
    &::before { top: 2rem; }
    &::after { bottom: 2rem; }

    &__icon {
        @extend %flex-center-center;
        background-color: var(--color-primary);
        border: 1.2rem solid #fff;
        border-radius: 99rem;
        color: #fff;
        font-size: 2.5rem;
        position: absolute;
        top: 0;
        left: 50%;
        transform: translate(-50%,-50%);
        height: 8.4rem;
        width: 8.4rem;
    }

    &__text {
        color: var(--color-primary-4);
        font-size: 4rem;
        font-style: italic;
        line-height: 1.6;
        text-align: center;
    }

    &__author {
        @extend %flex-center-center;
        flex-flow: column nowrap;
        position: absolute;
        bottom: -26rem;
        left: 50%;
        text-align: center;
        transform: translateX(-50%);
        width: 100%;
        z-index: 5;

        &__text {
            display: inline-flex;
            align-items: center;
            justify-content: center;
            flex-flow: row wrap;
            background-color: var(--color-primary);
            color: #fff;
            font-size: 2.5rem;
            gap: 0 2.5rem;
            padding: 1rem 3rem;
            position: relative;
            z-index: 2;

            span { 
                display: block;
                flex-shrink: 0; 
                text-align: center;
            }
            span:first-child { font-weight: bold; }
            span:last-child { text-transform: uppercase; }
        }

        &__image {
            @extend %new-stack;
            display: inline-block;
            height: 34.4rem;
            width: 34.4rem;
            z-index: 1;

            &::before {
                content: "";
                background-color: #fff;
                border-radius: 99rem;
                position: absolute;
                inset: -3rem;
                z-index: -1;
            }

            img {
                border-radius: 99rem;
            }
        }
    }
}