.menu-toggle {
    background-color: transparent;
    border: none;
    cursor: pointer;
    display: block;
    flex-flow: column nowrap;
    justify-content: space-between;
    position: relative;
    height: 2.5rem;
    width: 4rem;

    &::before, &::after, & > span {
        background-color: #fff;
        border-radius: 99rem;
        display: block;
        position: absolute;
        left: 0;
        height: 3px;
        transition: $transition;
    }
    &::before {
        content: "";
        top: 0;
        width: 100%;
    }
    & > span {
        top: calc(50% - 2px);
        width: 75%;
    }
    &::after {
        content: "";
        bottom: 0;
        width: 50%;
    }

    &:hover {
        &::before, &::after, & > span {
            background-color: var(--color-primary);
            width: 100%;
        }
    }
}

.shooting-star {
    position: relative;

    &::after {
        @include before-after-background(
            $url: '/storage/svg/shooting-star.svg',
            $top: 100%,
            $right: 0,
            $width: 24rem,
            $height: 8rem,
        );
    }
}