@function clamp-calc($min-width, $max-width, $size-at-min-width, $size-at-max-width) {
    $slope: ($size-at-max-width - $size-at-min-width) / ($max-width - $min-width);
    $y-axis-intersection: -1 * $min-width * $slope + $size-at-min-width;
    $return-value: clamp(#{$size-at-min-width}, #{$y-axis-intersection} + #{$slope} * 100vw, #{$size-at-max-width});
    @return $return-value;
}

@mixin positionCoords($position, $top, $left, $right, $bottom) {
    position: $position;
    @if ($top != 'null') { top: $top; }
    @if ($left != 'null') { left: $left; }
    @if ($right != 'null') { right: $right; }
    @if ($bottom != 'null') { bottom: $bottom; }
}

@mixin positionTranslate($centered) {
    @if ($centered == 'X') {
        transform: translateX(-50%);
    } @else if ($centered == 'Y') {
        transform: translateY(-50%);
    } @else if ($centered == 'XY') {
        transform: translate(-50%,-50%);
    }
}

@mixin shape-elt($width, $height: 'null', $rounded: 'null') {
    @include squarify($width, $height);
    @if ($rounded != 'null') {
        @if ($rounded == 'round') {
            border-radius: 999rem;
        } @else {
            border-radius: $rounded;
        }
    } 
}

@mixin squarify($width, $height) {
    width: $width;
    @if ($height != 'null') {
        height: $height;
    } @else {
        height: $width;
    }
}