.cart {
    margin-bottom: 1.5rem;

    &__item {
        @extend .side-panel__list__item;
        border-top: none;
        gap: 3rem;
        padding-bottom: 5rem;
        padding-right: clamp-calc(120rem, $grid-width-default, 4rem, 10rem);
        position: relative;

        &::after {
            @include before-after-background(
                $url: '/storage/svg/african-line-overlay-1-secondary-white.svg',
                $repeat: repeat,
                $size: contain,
                $bottom: 0,
                $left: 50%,
                $width: 100vw,
                $height: 3.3rem,
                $self-centered: 'X',
            );
        }

        .sub { margin-bottom: 1rem; }
        .price_wrap { margin-left: auto; }
        .price { font-size: 3.5rem; }
    }


    // Variant 1
    &--2 {
        
        .cart__item {
            padding: 0;
            margin-bottom: 1.5rem;

            &::after { display: none; }
        }

        .image a { background-color: #fff; }
        .price { font-size: 2.8rem; }
    }
}