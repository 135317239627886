@include media('<96.87em') {
    .section-banner {
        &__slide {
            .left {
                padding: 22rem 4rem 4rem 2.5rem;
            }
        }
    }
}

@include media('<md') {

    // Components --> MENU
    .menu-overlay {
        .container {
            @include change-grid-columns-custom(45% 55%);
        }
    } 

    // Components --> REFERENCE
    .reference {
        flex-flow: column nowrap;
        align-items: center;
        margin-bottom: 5rem;

        &::before {
            display: none;
        }

        &__content,
        &--1 .reference__content {
            order: 2;
            margin: -5rem 0 0 0;
            transform: none;
        }
        &--1 .reference__image {
            order: 1;
        }
    }

    // Layout --> ECOMMERCE
    .page-ecommerce.page-cart {
        .container {
            @include change-grid-columns-custom(60% 40%);
        }
        .left {
            padding: 1.5rem 0 3rem 0;
        }
        .right {
            &--1 {
                .right__content {
                    padding-top: 5rem;
                }
            }
        }
    }

    // Layout --> PAGE-HEADER
    .page-header {
        &__right { display: none; }
        &__title { max-width: 100%; }
    }

    // Pages --> HOME
    .section-intro {
        padding: 25rem 0 10rem 0;
        position: relative;
        &::after { 
            display: none;
        }
        // &::after { 
        //     left: auto;
        //     right: 5%;
        //     transform: translateY(25%);
        //     height: 42.7rem;
        //     width: 38rem;
        //     z-index: 10;
        // }
        .container {
            @include change-grid-columns-custom(1fr);
        }
        .left { 
            order: 2; 
            img {
                max-height: 55rem;
            }
        }
        .right { order: 1; }
    }

    .section-quote {
        &__inauguration {
            flex-flow: row wrap;
            margin: 30px auto 0 auto;
            max-width: 75rem
        }
        .sqi-center {
            flex-grow: 1;
            order: 1;
            width: calc(100vw - 4rem);
        }
        .sqi-left {
            flex: 1 1 40%;
            order: 2;
        }
        .sqi-right {
            flex: 1 1 40%;
            order: 3;
        }
    }

    // Pages --> ECOMMERCE
    .page-ecommerce.page-checkout {
        .container {
            display: block;
        }
        .left {
            padding: 1.5rem 0 3rem 0;
        }
        .right {
            &::after { 
                left: 50%;
                transform: translateX(-50%);   
                z-index: -5; 
            }

            &__content {
                padding: 3rem 1.5rem 7rem 1.5rem;
                margin: 0 auto;
                max-width: 60rem;
            }
        }
        .page-checkout__leftButtons { display: none; }
    }
}

@include media('<58.75em') {

    // Pages --> HOME
    .section-banner {
        .home-banner-slider {
            height: calc(100rem + 25vw);
        }
        &__slide {
            &__overlay-1 { display: none; }
            .container {
                display: block;
            }
            .left { 
                padding-left: 0; 
                padding-right: 0; 
                margin-bottom: 6vw;
            }
            .right {
                padding: 0;
                height: 30rem;
                img {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%,-50%);
                    max-width: 37rem;
                }
            }

            .banner-title {
                font-size: clamp-calc(50rem, 110rem, 5rem, 9rem);
                text-align: center;
                span:last-child { 
                    white-space: normal; 
                }
            }
            .banner-desc {
                font-size: clamp-calc(50rem, 110rem, 2.2rem, 4rem);
                text-align: center;
            }
            .btn-wrap {
                justify-content: center;
            }
        }
    }

}

@include media('<sm') {

    // Components --> CARD
    .card-image {
        &__image {
            display: none;
        }
        &__content {
            padding: 5rem 5rem 10rem;
            margin-left: 0;
        }
    }
    
    // Components --> MENU
    .menu-overlay {
        .container {
            @include change-grid-columns-custom(1fr);
        }
        .left {
            display: none;
        }
    } 

    // Pages --> HOME
    .section-respect {
        .section-content {
            @include change-grid-columns-custom(1fr);
            margin-top: -4rem;
            .left img {
                max-height: 60rem;
            }
            .right {
                padding-top: 2rem;
            }
        }
    }

    // Pages --> PRODUCT-SINGLE
    .product-single-top {
        .container {
            @include change-grid-columns(1);
        }
        .left {
            width: 100%;
        }
    }
    .product-single-slider {
        max-width: calc(100vw - ($grid-gap-default*2));
        width: 100%;
    }

    // Pages --> ECOMMERCE
    .page-ecommerce.page-cart {
        .container {
            display: block;
        }
        .right {
            &::after { 
                left: 50%;
                transform: translateX(-50%);   
                z-index: -5; 
            }

            &__content {
                padding: 1.5rem 1.5rem 7rem 1.5rem;
                margin: 0 auto;
                max-width: 60rem;
            }
        }
    }
}

@include media('<sms') {
    
    // Layout --> HEADER
    .header {
        &__action { display: none; }
    }

    // Components --> BOTTOM-NAVIGATION
    .bottom-navigation {
        display: flex;
    }
}

@include media('<xs') {

    // Base --> TYPOGRAPHY
    .section-title__main,
    .h1, h1 { 
        font-size: 7rem; 
    }
    .h2, h2 { font-size: 5rem; }
    .h3, h3 { font-size: 4rem; }
    .h4, h4 { font-size: 3.5rem; }

    // Components --> PRODUCT-CARD
    .product-card {
        gap: 1rem;
        flex-flow: column wrap;

        .product-card__image {
            &::before {
                aspect-ratio: 1/1;
                top: 50%;
                left: 50%;
                height: auto;
                max-width: 30rem;
                width: 100%;
                transform: translate(-50%,-50%);
            }
            padding-left: 0;
            height: 35rem;
            max-width: 40rem;
            width: 100%;

            img {
                max-width: 28rem;
            }
        }
        .product-card__tags {
            justify-content: center;
        }
        .product-card__content {
            text-align: center;
        }
        .product-card__title {
            margin-bottom: .5rem;
            min-height: 0;
        }
        .product-card__price {
            font-size: 5rem;
        }
        .product-card__buttons {
            justify-content: center;
        }
    }

    // Components --> SCROLL-TOP
    .scroll-top {
        bottom: 9rem;
    }

    // Components --> MENU
    .menu-overlay {
        &__language { 
            display: block; 
            text-align: center;
        }
        .language-switcher {
            display: inline-block;
        }
    }

    // Pages --> LOGIN
    .page-login {
        .container {
            display: block;
            .content {
                &::before { left: -200rem; }
            }
            .content_wrap {
                padding: 7rem 0;
                width: 100%;
                &::before { left: -200rem; }
            }
        }
    }
}



@include media('<xss') {

    // Components --> CART
    .cart {
        &__item {
            gap: 1rem;
            flex-flow: column nowrap;
            align-items: center;
            text-align: center;
            .image {
                width: 100%;
            }
            .title {
                padding-right: 0;
            }
            .price_wrap {
                margin-left: 0;
            }
        }
    }

    // Layout --> HEADER
    .header {
        .language-switcher { display: none; }
    }
    
}