.reference {
    @extend %flex-align-start;
    margin-bottom: 15rem;
    position: relative;

    &::before {
        content: "";
        border: 5px solid var(--color-primary);
        border-radius: 99rem;
        position: absolute;
        top: -6rem;
        left: 30rem;
        width: 29rem;
        height: 29rem;
        z-index: 1;
    }

    &__image {
        border-radius: 999rem;
        position: relative;
        z-index: 0;
    }

    &__content {
        @extend .card--1;
        margin-left: clamp-calc(92rem, $grid-width-default, -50rem, -8rem);
        transform: translateY(5rem);
    }

    &__title {
        @extend %h2;
    }

    &__desc {
        font-size: 2.8rem;
        & > * {
            margin-bottom: 4rem;
        }
    }

    // Variant 1
    &--1 {
        &::before {
            left: auto;
            right: 30rem;
        }
        .reference__image {
            order: 2;
        }
        .reference__content {
            order: 1;
            margin-left: 0;
            margin-right: clamp-calc(92rem, $grid-width-default, -50rem, -8rem);
        }
    }
}