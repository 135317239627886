.page-ecommerce {
    @extend %grid-base;

    .container {
        @include create-grid-columns-custom(
            $columns: 55% 45%,
            $grid-gap: 0,
        );
    }

    .left {
        padding: 6rem 6rem 10rem 0;
    }

    .right {
        @extend %new-stack;
        background-color: var(--color-secondary-2);

        &::before {
            content: "";
            background: url('/storage/svg/african-line-overlay-white.svg') center top;
            background-size: contain;
            opacity: .8;
            position: absolute;
            top: 1rem;
            left: 1rem;
            width: 3.5rem;
            height: 100%;
            z-index: -1;
        }

        &::after {
            content: "";
            background-color: var(--color-secondary-2);
            position: absolute;
            top: 0;
            left: 100%;
            height: 100%;
            width: 999rem;
            z-index: -1;
        }

        &__content {
            @extend %new-stack;
            padding: 3rem 0 10rem 6rem;
            position: relative;
            &::before {
                @include before-after-background(
                    $url: '/storage/svg/leaf-overlay-1-white.svg',
                    $top: -8rem,
                    $right: -14rem,
                    $width: 39.8rem,
                    $height: 40.8rem,
                );
                opacity: .6;
                transform: scaleX(-1);
                z-index: -1;
            }
        }

        &--1 {
            .right__content {
                padding-top: 10rem;
            }
        }
    }
}