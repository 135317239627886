.product-card {
    @extend %flex-align-center;
    gap: 3rem;
    position: relative;

    &__image {
        @extend %flex-center-center;
        @extend %new-stack;
        flex-shrink: 0;
        padding-left: 5%;
        text-align: center;
        position: relative;
        height: 40rem;
        width: 25rem;

        &::before {
            content: "";
            background-color: var(--color-secondary-2);
            border-radius: 99rem;
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 12.8rem;
            z-index: -1;
        }

        img {
            max-width: 22.2rem;
            max-height: 32.4rem;
        }
    }

    &__tags {
        @extend %flex-align-center;
        gap: .5rem;
        margin-bottom: .5rem;
    }

    &__tag {
        @extend .pellet-primary;
    }

    &__content {
        flex-grow: 1;
    }

    &__title {
        color: var(--color-secondary);
        display: block;
        font-size: 3rem;
        text-transform: uppercase;
        min-height: 7.2rem;
    }

    &__price {
        color: var(--color-primary);
        font-size: 6rem;
        font-weight: bold;
        line-height: 1;
        margin-bottom: 1.5rem;
    }

    &__desc {
        font-size: 2.2rem;
        margin-bottom: 1rem;
    }

    &__buttons, &__actions {
        @extend %flex-align-center;
        flex-flow: row wrap;
        gap: 2.2rem;
    }

    // Variant 1
    &--2 {
        gap: 1rem;
        flex-flow: column wrap;
        margin: 0 auto;
        max-width: 48rem;

        .product-card__image {
            &::before {
                aspect-ratio: 1/1;
                top: 50%;
                left: 50%;
                height: auto;
                max-width: 30rem;
                width: 100%;
                transform: translate(-50%,-50%);
            }
            padding-left: 0;
            height: 35rem;
            max-width: 40rem;
            width: 100%;

            img {
                max-width: 28rem;
            }
        }
        .product-card__tags {
            justify-content: center;
        }
        .product-card__content {
            text-align: center;
        }
        .product-card__title {
            margin-bottom: .5rem;
        }
        .product-card__price {
            font-size: 5rem;
        }
        .product-card__buttons {
            justify-content: center;
        }
    }
}


// .product-card-2 {
//     @extend %flex-center-between;
//     flex-flow: column nowrap;

//     &__image {
//         @extend %new-stack;
//         margin-bottom: 1rem;
//         text-align: center;

//         &::before {
//             content: "";
//             position: absolute;
            
//         }

//         img {
//             border-radius: 99rem;
//             max-width: 63rem;
//         }
//     }
//     &__title {
//         @extend %h4;
//         color: var(--color-primary-4);
//         text-align: center;
//         max-width: 30rem;
//     }
//     &__link {
//         @extend %link;
//         color: #fff;
//         font-size: 2.2rem;
//     }
// }