h1, .h1 { @extend %h1; }
h2, .h2 { @extend %h2; }
h3, .h3 { @extend %h3; }
h4, .h4 { @extend %h4; }
h5, .h5 { @extend %h5; }
h6, .h6 { @extend %h6; }

.link {
    @extend %link;
    display: inline-flex;
    align-items: center;
    gap: 1.5rem;

    &--gapSm {
        gap: .5rem;
    }

    &--white {
        color: #fff;
        &:hover, &:focus-visible {
            color: #fff;
            &::after { background-color: #fff; }
        }
    }

    &--tertiary {
        color: var(--color-tertiary);
        &:hover, &:focus-visible {
            color: var(--color-tertiary);
            &::after { background-color: var(--color-tertiary); }
        }
    }

    &--sm {
        font-size: 1.8rem;
    }
    &--lg {
        font-size: 2.5rem;
    }
}

.paras {
    & > p:not(:last-child) {
        margin-bottom: .8em;
    }

    &--lg {
        font-size: 2.4rem;
    }
    &--xl {
        font-size: 2.8rem;
    }
}

.quote-text {
    color: var(--color-primary);

    q {
        font-style: italic;
        font-weight: bold;
    }
}

.section-title {
    margin-bottom: 5rem;
    &__main {
        @extend %h1;
        margin-bottom: 2rem;
    }

    // Variant 1
    &--1 {
        position: relative;
        text-align: center;
        .section-title__sup {
            @extend %h3;
            color: #fff;
        }
        .section-title__main {
            background-color: #fff;
            display: inline-block;
            padding: .5rem 4rem;
        }
        .section-title__icon {
            @extend %flex-center-center;
            background-color: var(--color-secondary);
            border: 1.2rem solid var(--color-primary-3);
            border-radius: 99rem;
            color: #fff;
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translate(-50%, 58%);
            height: 8.4rem;
            width: 8.4rem;
        }
    }
}

.text-note {
    font-size: 2.8rem;
    font-style: italic;
}

.text-written {
    font-family: var(--font-family-3rd);
    line-height: 1.3;
    text-align: left;
    transform: rotate(-5deg);

    span { display: inline-block; }
    span:last-child {
        margin-left: 8rem;
    }

    &--1 {
        font-size: 3.4rem;
        span:last-child {
            font-size: 1.5em;
        }
    }

    &--2 {
        font-size: 5rem;
    }
}
