.coupon-code {
    position: relative;
    &__input {
        @extend %is-hidden;
        position: absolute;
        transition: $transition;
    }

    &.active {
        .coupon-code__button { display: none; }
        .coupon-code__input {
            @extend %is-visible;
            position: relative;
        }
    }

}

.list-group .list-group-item:last-child {
    border: none !important;
}

.separator-1 {
    margin: 2rem 0;
    position: relative;
    height: 9.3rem;
    width: 100%;

    &::before {
        content: "";
        background-image: url('/storage/svg/african-line-overlay-1-secondary-white.svg');
        background-size: contain;
        position: absolute;
        left: 50%;
        top: 0;
        transform: translateX(-50%);
        height: 100%;
        width: 200vw
    }
}