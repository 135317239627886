.page-thank-you {
    background: url('/storage/baobabs-at-sunrise-background-w1920x896.jpg') center 38rem no-repeat;
    background-color: var(--color-primary-3);
    background-size: cover;
    color: #fff;
    padding: 16rem 0 15rem 0;
    text-align: center; 
}

.thanks-img {
    aspect-ratio: 1/1;
    display: inline-block;
    margin-bottom: 6rem;
    position: relative;
    width: 26rem;

    img { 
        @extend %image-cover; 
        border-radius: 99rem;
    }

    i {
        @extend %flex-center-center;
        background-color: var(--color-primary);
        border: 1rem solid var(--color-primary-3);
        border-radius: 99rem;
        color: #fff;
        font-size: 3.5rem;
        line-height: 0;
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translate(-50%,50%);
        height: 8.5rem;
        width: 8.5rem;
    }

    &::after {
        content: "";
        background-color: #fff;
        position: absolute;
        left: 50%;
        bottom: 90%;
        height: 100vh;
        width: 3px;
        transform: translateX(-50%);
    }
}