.stable-price {
    margin-bottom: 5rem;

    &__item {
        @extend %flex-center-between;
        margin-bottom: 1rem;
        span:first-child {
            color: var(--color-tertiary);
            font-weight: bold;
        }
        span:last-child {
            color: var(--color-secondary);
            font-size: 1.2em;
            font-weight: bold;
            text-align: right;
        }

        &--feat {
            span:last-child {
                color: var(--color-primary);
                font-size: 1.5em;
            }
        }
    }
}