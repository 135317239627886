.section-banner {
    background-color: var(--color-primary-3);

    .home-banner-slider {
        height: 75rem;
        width: 100%;
    }

    &__slide {
        @extend %grid-base;
        background-color: var(--color-primary-3);
        overflow: hidden;
        position: relative;

        &__overlay-1 {
            @extend %background-cover-center;
            border-radius: 999rem;
            position: absolute;
            top: 12%;
            left: calc(50% - 9.25rem);
            height: 18.5rem;
            width: 18.5rem;
            transform: translateX(-50%);
            z-index: 5;
        }

        .container {
            @include create-grid-columns-custom(repeat(2, 1fr), 0);
        }
        .left {
            @extend %new-stack;
            padding: 22rem 4rem 4rem calc(50vw - 75rem);
            position: relative;

            &::before {
                @include before-after-background(
                    $url: '/storage/svg/baobab-fruit-white.svg',
                    $top: -10%,
                    $left: -30%,
                    $width: 74rem,
                    $height: 71rem,
                );
                opacity: .13;
                z-index: -2;
            }
        }
        .right {
            @extend %new-stack;
            @extend %flex-end-center;
            background-color: var(--color-tertiary);
            padding: 14rem 4rem 4rem 4rem;

            &::before {
                content: "";
                background-color: var(--color-tertiary);
                position: absolute;
                top: 0;
                left: 0;
                height: 100%;
                width: 999vw;
                z-index: -1;
            }

            &::after {
                @include before-after-background(
                    $url: '/storage/logo/logo-biokide-illustration-style-2-1.svg',
                    $bottom: -5%,
                    $right: -35%,
                    $width: 60rem,
                    $height: 60.5rem,
                );
                opacity: .06;
                z-index: -1;
            }

            img {
                width: 80%;
                max-width: 47.5rem;
            }
        }
    }

    .banner-title {
        color: #fff;
        font-size: clamp-calc(120rem, $grid-width-default, 6rem, 7.2rem);
        letter-spacing: .08em;
        line-height: 1;
        margin-bottom: 4rem;

        span { display: inline-block; }
        span:last-child {
            @extend %new-stack;
            font-size: 1.3em;
            font-weight: bold;
            position: relative;
            white-space: nowrap;

            &::before {
                @include before-after-background(
                    $url: '/storage/cream-dash-1.png',
                    $bottom: -25%,
                    $right: -2%,
                    $width: 5em,
                    $height: 2.5em,
                );
                transform: translateY(50%);
                z-index: -1;
            }
        }
    }

    .banner-desc {
        color: #fff;
        font-size: clamp-calc(120rem, $grid-width-default, 2.5rem, 3rem);
        margin-bottom: 6rem;
    }
}

.section-intro {
    background-color: var(--color-primary-3);
    background-image: url('/storage/baobabs-at-sunrise-background-w1920x896.jpg');
    background-position: center bottom;
    background-repeat: no-repeat;
    padding: 25rem 0;
    position: relative;

    &::before {
        @include before-after-background(
            $url: '/storage/svg/african-line-overlay-1-white.svg',
            $top: 3rem,
            $left: 50%,
            $width: 100%,
            $height: 17rem,
            $self-centered: 'X',
        );
        opacity: .2;
    }

    &::after {
        @include before-after-background(
            $url: '/storage/lady-in-circle-effect-w500.png',
            $bottom: 0,
            $left: 65%,
            $width: 41.9rem,
            $height: 47.1rem,
        );
        transform: translateY(60%);
    }

    .container {
        @include create-grid-columns-custom(25% 1fr);
    }

    .left {
        text-align: center;
    }

    .right {
        text-align: center;
        * { color: #fff; }
    }

    &__block-1 {
        @extend %flex-center-center;
        flex-flow: row wrap;
        gap: 2rem 8rem;
        margin: 4rem 0;
    }
}

.section-products {
    @extend %new-stack;
    overflow: hidden;
    padding: 10rem 0;

    &::before {
        @include before-after-background(
            $url: '/storage/svg/leaf-overlay-1.svg',
            $top: -4rem,
            $left: 6rem,
            $width: 33.4rem,
            $height: 34.2rem,
        );
        z-index: -1;
    }

    &::after {
        @include before-after-background(
            $url: '/storage/svg/leaf-overlay-2.svg',
            $bottom: -5rem,
            $right: 2rem,
            $width: 55rem,
            $height: 32rem,
        );
        z-index: -1;
    }
}

.section-respect {
    @extend %new-stack;
    background-color: var(--color-primary-3);
    background-image: url('/storage/left-nature-section-w1920.jpg');
    background-position: center bottom;
    background-repeat: no-repeat;
    padding: 10rem 0;

    &::before {
        @include before-after-background(
            $url: '/storage/svg/africa-map-white.svg',
            $width: 93.3rem,
            $height: 104.9rem,
            $top: -5rem,
            $right: -10rem,
        );
        opacity: .13;
        z-index: -1;
    }

    .section-title {
        position: relative;
        z-index: 1;
    }

    .section-content {
        @include create-grid-columns-custom(3fr 5fr, 10rem);
        margin-top: -10rem;

        .left {
            text-align: center;
        }

        .right {
            color: #fff;
            padding-top: 15rem;
        }
    }
}

.section-quote {
    @extend %new-stack;
    padding: 10rem 0;
    position: relative;
    z-index: 1;

    &::before, &::after {
        content: "";
        background: url('/storage/svg/baobab-draw.svg') center top no-repeat;
        background-size: cover;
        opacity: .65;
        position: absolute;
        bottom: 0;
        width: 93.6rem;
        height: 80rem;
        z-index: -1;
    }
 
    &::before { left: -35rem; }
    &::after { right: -35rem; }
    
    &__inauguration {
        display: flex;
        align-items: stretch;
        justify-content: center;
        gap: 2.5rem;
        margin-top: 2.5rem;
    }
    

    .sqi-left,
    .sqi-right {
        text-align: center;
    }

    .sqi-center {
        max-width: 50rem;

        & > * { 
            display: flex;
            flex-flow: column nowrap;
            align-items: center;
            justify-content: center;
            height: 100%; 
        }
    }
}

.section-references {
    @extend %new-stack;
    background-color: var(--color-primary-4);
    overflow: hidden;
    padding: 10rem 0;

    &::before, &::after {
        content: "";
        background: url('/storage/svg/african-line-overlay-2.svg') center top;
        background-size: contain;
        opacity: .08;
        position: absolute;
        top: 2rem;
        width: 6.5rem;
        height: 100%;
        z-index: -1;
    }
    &::before {
        left: 2rem;
    }
    &::after {
        right: 2rem;
        transform: scaleX(-1);
    }
}