.checkbox-group {
    cursor: pointer;
    display: inline-block;

    input { 
        display: none; 

        &:checked {
            & ~ .label {
                color: var(--color-primary);
                &::before {
                    background-color: var(--color-primary);
                    border-color: var(--color-primary);
                }
            }
        }
    }

    .label {
        display: inline-flex;
        align-items: flex-start;
        position: relative;
        transition: $transition;

        &::before {
            @extend %fig-check;
            background-color: #fff;
            border: 2px solid rgba(#B2A893, .3);
            color: #fff;
            display: inline-block;
            margin-right: 1rem;
            padding: 2px;
            transition: $transition;
        }
    }  
    
    // Radio variant
    &--radio {
        .label {
            &::before { 
                border-radius: 99rem; 
            }
        }
    }
}

.qty {
    @extend %flex-center-center;
    height: 6.5rem;
    padding: .5rem 1rem;

    &__remove::before { @extend %fig-minus-small; transform: none; }
    &__add::before { @extend %fig-plus-small; transform: none; }
    &__remove, &__add {
        @extend %button-icon;
        background-color: var(--color-secondary);
        color: #fff;
        height: 4.5rem;
        width: 4.5rem;
    }

    &__input { 
        background: transparent;
        border: none;
        color: var(--color-tertiary);
        font-family: $body-font;
        font-size: 3.5rem;
        font-weight: bold;
        text-align: center;
        width: 7rem;
    }

    &--sm {
        height: auto;

        .qty__remove, .qty__add {
            height: 3rem;
            width: 3rem;
        }

        .qty__input {
            font-size: 2.5rem;
            width: 5rem;
        }
    }
}

.fieldset-1 {
    border: none;
    margin-bottom: 3rem;

    legend { @extend %h4; }
}

.form-group {
    margin-bottom: 2rem;
}
.form-group-sm {
    margin-bottom: 1rem;
}

.form-group-button {
    display: flex;
    flex-flow: row wrap;

    input {
        background-color: #fff;
        border: 2px solid var(--color-tertiary-2);
        flex-grow: 1;
        font-size: 2rem;
        font-weight: bold;
        padding: .5rem 2rem;
        height: 5rem;
        transition: $transition;

        &:hover, &:focus-visible {
            border-color: var(--color-primary);
            outline: none !important;
        }
    }

    button {
        @extend .button-tertiary;
        @extend .button--sm;
    }
}

.input-1 {
    background-color: #fff;
    border: 2px solid rgba(#B2A893, .3);
    color: var(--color-tertiary);
    display: block;
    font-size: 1.8rem;
    font-weight: bold;
    padding: .5em 1em;
    width: 100%;
    transition: $transition;

    &:hover, &:focus-visible {
        border-color: var(--color-primary);
        outline: none;
    }
}

.notif {
    display: inline-block;
    font-size: 1.8rem;
    font-weight: bold;
    margin-top: .4rem;
    padding-left: 2.5rem;
    position: relative;

    &::before, &::after {
        content: "";
        border-radius: 99rem;
        position: absolute;
        left: .8rem;
        top: .8em;
        height: .6rem;
        width: .6rem;
        -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
    }

    &::after {
        -webkit-animation: growTransp 1s infinite backwards;
        animation: growTransp 1s infinite backwards;
    }

    &--success {
        color: var(--color-success);
        &::before, &::after { background-color: var(--color-success); }
    }

    &--danger {
        color: var(--color-danger);
        &::before, &::after { background-color: var(--color-danger); }
    }
}

.see-password-wrap {
    position: relative;

    .see-password {
        cursor: pointer;
        line-height: 1;
        position: absolute;
        top: 50%;
        right: 2rem;
        transform: translateY(-50%);
        &::before { @extend %fig-eye-crossed; }
        &.active::before { @extend %fig-eye; color: var(--color-primary); }
    }
}

.select-nice {
    @extend .input-1;
    line-height: 1.2;
    padding-right: 4rem;
    position: relative;

    &::before {
        content: "►";
        color: var(--color-primary);
        display: inline-block;
        font-size: 0.8em;
        position: absolute;
        right: 1.5rem;
        top: 50%;
        transform: translateY(-50%) rotate(90deg);
    }
}

.notif {
    color: var(--color-danger);
    display: block;
    font-size: 14px;
    font-weight: normal;
    line-height: 22px;
    padding-left: 15px;
    position: relative;

    &::before {
        content: "";
        border-radius: 10px;
        background-color: var(--color-danger);
        line-height: 0;
        position: absolute;
        left: .3rem;
        top: 1.1rem;
        height: 6px;
        width: 6px;
    }

    &::after {
        content: "";
        background-color: var(--color-danger);
        border-radius: 4px;
        position: absolute;
        top: .9rem;
        left: .1rem;
        height: 4px;
        width: 4px;
        animation: grow-transp 1s ease-in-out infinite forwards;
    }
}

.notif-form-success {
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
        flex-shrink: 0;
        margin-right: 15px;
        height: 50px;
        width: 50px;
        
        & > * { 
            stroke-dasharray: 1200;
            stroke-dashoffset: 1200;
            stroke: var(--color-success); 
        }
        & > *:first-child { animation: stroke-appear-1 .75s .5s cubic-bezier(0.33, 1, 0.68, 1) forwards; }
        & > *:last-child { animation: stroke-appear-1 1s 1s cubic-bezier(0.5, 1, 0.89, 1) forwards; }
    }

    span {
        color: var(--color-success);
        font-size: 18px;
        animation: fade-in .5s ease-in-out forwards;
    }
}


// Animations
@keyframes fade-in {
    from { opacity: 0; }
    to { opacity: 1; }
}
@keyframes grow-transp {
    from { opacity: .8; transform: scale(1); }
    to { opacity: 0; transform: scale(4); }
}
@keyframes stroke-appear-1 {
    from { 
        stroke-dasharray: 1200;
        stroke-dashoffset: 1200;
    }
    to {
        stroke-dasharray: 1200;
        stroke-dashoffset: 0;
    }
}