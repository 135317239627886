.page-login {
    background: url('/storage/beautiful-natural-young-woman-portrait-reversed-w1920.jpg') calc(30vw - 71rem) center no-repeat;
    background-size: auto 100%;
    @extend %grid-base;
    min-height: 80rem;

    .container {
        @include create-grid-columns-custom(1fr minmax(60rem, 45%));
    }
    
    .content {
        @extend %new-stack;
        display: flex;
        justify-content: flex-end;
        grid-column: 2 / -1;
        padding-top: 25rem;

        &::before {
            content: "";
            background-color: var(--color-tertiary);
            position: absolute;
            top: 0;
            left: 0;
            height: 41rem;
            width: 999rem;
            z-index: -2;
        }

        &::after {
            @include before-after-background(
                $url: '/storage/logo/logo-biokide-illustration-style-2-1.svg',
                $position: top center,
                $size: 60rem 60.5rem,
                $top: 41rem,
                $left: 0,
                $width: 190%,
                $height: 38.5rem,
            );
            opacity: .06;
            transform: translateY(-100%);
            z-index: -1;
        }
    }

    .content_wrap {
        @extend %new-stack;
        background-color: var(--color-secondary-2);
        padding: 7rem 5rem;
        width: calc(100% - 4rem);

        &::before {
            content: "";
            background-color: var(--color-secondary-2);
            position: absolute;
            top: 0;
            left: 100%;
            height: 100%;
            width: 999rem;
            z-index: -1;
        }

        
        &::after {
            @include before-after-background(
                $url: '/storage/svg/leaf-overlay-1-white.svg',
                $position: center bottom,
                $size: 39.8rem 40.8rem,
                $top: 0,
                $right: -14rem,
                $width: 39.8rem,
                $height: 32.8rem,
            );
            opacity: .6;
            transform: scaleX(-1);
            z-index: -1;
        }
    }

    &__form {
        padding-bottom: 6rem;
        margin-bottom: 2rem;
        position: relative;
        &::after {
            @include before-after-background(
                $url: '/storage/svg/african-line-overlay-1-white.svg',
                $position: left center,
                $size: contain,
                $bottom: 0,
                $left: -5rem,
                $width: 100vw,
                $height: 5rem,
            );
        }
    }
}