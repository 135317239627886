.carousel-navigations {
    @extend %flex-align-center;
    gap: 3rem;
    margin-bottom: 3rem;
    .prev, .next {
        @extend %button-icon;
        background-color: var(--color-secondary);
        border-color: var(--color-secondary);
        color: #fff; 

        &:hover, &:focus-visible {
            background-color: transparent;
            color: var(--color-secondary);
        }
    }
    .prev::before {
        @extend %fig-angle-left;
        transform: translateX(-1px);
    }
    .next::before {
        @extend %fig-angle-right;
        transform: translateX(1px);
    }
}

.carousel-navigations-2 {
    @extend %flex-center-center;
    gap: 3rem;
    margin-top: 1.5rem;

    .prev, .next {
        @extend %flex-center-center;
        height: 4rem;
        width: 4rem;
    }
    .prev::before, .next::before {
        color: var(--color-secondary);
        cursor: pointer;
        transition: $transition;
        transform: none;

        &:hover {
            color: var(--color-primary);
        }
    }
    .prev::before {
        @extend %fig-angle-left;
    }
    .next::before {
        @extend %fig-angle-right;
    }
    .pagination {
        @extend %flex-center-center;
        gap: 1rem;
        flex-shrink: 0;
        &__bullet {
            border: 2px solid var(--color-primary);
            border-radius: 9rem;
            cursor: pointer;
            height: 2.5rem;
            width: 2.5rem;
            transition: $transition;

            &--active {
                background-color: var(--color-primary);
            }
        }
    }
}