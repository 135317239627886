.menu-overlay {
    @extend %is-hidden;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    z-index: 99999;
    transition: opacity $transition, visibility $transition;

    &__content {
        @extend %grid-base;
        @extend %new-stack;
        @include background-image(
            $url:'/storage/baobab-background-2-w1920.jpg',
            $position: center top,
        );
        overflow-x: hidden;
        position: absolute;
        inset: 0;

        &::before {
            @include before-after-background(
                $url: '/storage/logo/logo-biokide-illustration-style-2-1.svg',
                $bottom: 1%,
                $right: 1%,
                $width: 60rem,
                $height: 60.5rem,
            );
            opacity: .06;
            z-index: -1;
        }
    }

    &__close {
        cursor: pointer;
        position: absolute;
        top: 5%;
        right: 5%;
        z-index: 1;


        &::before {
            @extend %fig-cross;
            transition: $transition;
        }

        &:hover, &:focus-visible {
            &::before {
                color: #fff;
            }
        }
    }

    &__language { display: none; }

    .container {
        @include create-grid-columns-custom(35% 65%);
        align-content: center;
    }

    .left, .right {
        @extend %is-hidden;
        padding: 2rem 2rem 0 2rem;
        transition: $transition;
    }

    .left {
        @extend %new-stack;
        transform: translateX(-25%);

        &::before {
            content: "";
            background-color: var(--color-primary);
            opacity: .25;
            position: absolute;
            bottom: 13.5%;
            right: 0;
            height: 44rem;
            width: 999vw;
            z-index: -1;
        }

        .product-card-2 {
            &__image {
                text-align: center;
                img {
                    border-radius: 99rem;
                    height: clamp(40rem, 70vh, 63rem);
                }
            }
        }
    }

    .right {
        transform: translateX(25%);
    }

    &__navigation {
        margin-bottom: 4rem;
        ul {
            @extend %flex-align-center;
            flex-flow: column nowrap;
            height: clamp(35rem, 80vh, 65rem);
        }
        li {
            @extend %flex-center-center;
            flex-grow: 1;
            position: relative;

            &::after {
                content: "";
                background-color: var(--color-secondary);
                position: absolute;
                bottom: 0;
                left: 50%;
                transform: translateX(-50%);
                height: 3px;
                width: 7.5rem;
            }
        }
        a {
            color: #fff;
            display: block;
            font-size: 4rem;
            font-weight: 300;
            letter-spacing: .1em;
            text-transform: uppercase;

            &:hover, &:focus-visible {
                color: var(--color-primary);
            }
        }
    }
}
.menu-overlay.active {
    @extend %is-visible;
    .left, .right {
        @extend %is-visible;
        transform: translateX(0);
    }
}