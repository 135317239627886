.card {
    background-color: #fff;
    padding: 5rem 6rem;

    &--1 {
        @extend %new-stack;
        padding-bottom: 12rem;
        overflow: hidden;
        z-index: 2;
    
        &::before {
            @include before-after-background(
                $url: '/storage/svg/baobab-fruit-secondary-white.svg',
                $top: -12rem,
                $right: -4rem,
                $width: 36.5rem,
                $height: 36.5rem,
            );
            z-index: -1;
        }
    
        &::after {
            @include before-after-background(
                $url: '/storage/svg/african-line-overlay-1-secondary-white.svg',
                $bottom: .5rem,
                $left: 0,
                $width: 100%,
                $height: 9.3rem,
                $size: auto 100%,
                $repeat: repeat-x,
            );
            z-index: -1;
        }
    }
}

.card-sm {
    padding: 2rem;
}

// Card image
.card-image {
    position: relative;
    &__image {
        border-radius: 999rem;
        overflow: hidden;
        position: absolute;
        left: 18rem;
        top: -5rem;
        transform: translateX(-50%);
        height: 90%;
        width: 28rem;
        z-index: 3;
        img { @extend %image-cover; }
    }
    &__content {
        @extend .card--1;
        background-color: var(--color-secondary-2);
        padding: 5rem 5rem 10rem 20rem;
        position: relative;
        margin-left: 18rem;
        &::before { background-image: url('/storage/svg/baobab-fruit-white.svg'); }
        &::after { background-image: url('/storage/svg/african-line-overlay-1-white.svg'); }
    }
}

.card-simple {
    background-color: var(--color-secondary-2);
    padding: 15rem 3rem;
    position: relative;

    &::before {
        content: "";
        background: url('/storage/svg/african-line-overlay-1-secondary-tt-1.svg') center center repeat-x;
        background-size: auto 100%;
        width: 100%;
        height: 8rem;
        z-index: 0;
        opacity: .2;
        position: absolute;
        left: 0;
        top: 0.5rem;
    }
    &::after {
        content: "";
        background: url('/storage/svg/african-line-overlay-1-secondary-tt-1.svg') center center repeat-x;
        background-size: auto 100%;
        width: 100%;
        height: 8rem;
        z-index: 0;
        opacity: .2;
        position: absolute;
        left: 0;
        bottom: 0.5rem;
    }

    &__textFeat {
        @extend .h5;
        margin-bottom: 2rem;
        text-align: center;
    }
}