*, 
*::before, 
*::after {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

html {
    font-size: 62.5%;

    @include media("<lg") {
        font-size: 50%;
    }
}

body {
    color: $body-color;
    font-family: $body-font;
    font-size: $body-size;
    font-weight: $body-weight;
    line-height: $body-line-height;

    &.preload * {
        transition: none !important;
    }
}

a {
    color: currentColor;
    text-decoration: none;
    transition: $transition;

    &:hover, &:focus {
        color: var(--color-primary);
    }
}

img {
    display: inline-block;
    max-width: 100%;
}

input, textarea {
    font-family: $body-font;
}

ul {
    list-style: none;
}

#wrapper {
    overflow-x: hidden;
    overflow-y: scroll;
    position: relative;
    height: 100vh;
    width: 100%;
}

::selection {
    background-color: var(--color-primary);
    color: #fff;
}

::-webkit-scrollbar {
    position: relative;
    width: 8px;
    z-index: 9999999999;
}
::-webkit-scrollbar-track {
    background: #000;
}
::-webkit-scrollbar-thumb {
    background: var(--color-primary);
    border-radius: 10px;
}