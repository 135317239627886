.page-header {
    @extend %new-stack;
    background-color: var(--color-primary-3);
    margin-bottom: 8rem;
    position: relative;
    z-index: 5;

    &::before {
        @include before-after-background(
            $url: '/storage/svg/baobab-fruit-white.svg',
            $position: center 40%,
            $size: 74rem 71rem,
            $top: 0,
            $left: -15rem,
            $width: 74rem,
            $height: 100%,
        );
        opacity: .1;
        z-index: -1;
    }

    &::after {
        content: "";
        background: url('/storage/svg/african-line-overlay-2.svg') center top;
        background-size: contain;
        opacity: .08;
        position: absolute;
        top: calc(100% + 6.5rem);
        left: 0;
        width: 5rem;
        height: 100vw;
        z-index: -1;
        transform: rotate(-90deg);
        transform-origin: top left;
    }
    
    &__content {
        padding-top: 18rem;
        padding-bottom: 2rem;
    }

    &__title {
        @extend %flex-align-center;
        @extend %h2;
        color: #fff;
        margin: 0; 
        height: 13rem;
        max-width: 53%;
    }

    &__right {
        background-color: var(--color-tertiary);
        position: absolute;
        right: 0;
        top: 0;
        height: calc(100% + 8rem);
        width: 45%;

        &::before {
            content: "";
            background-color: var(--color-tertiary);
            position: absolute;
            top: 0;
            left: 100%;
            height: 100%;
            width: 999rem;
        }

        &::after {
            @include before-after-background(
                $url: '/storage/logo/logo-biokide-illustration-style-2-1.svg',
                $position: top center,
                $size: 60rem 60.5rem,
                $bottom: 0,
                $left: 0,
                $width: 190%,
                $height: 38.5rem,
            );
            opacity: .06;
        }
    }
}