.side-panel {
    @extend %is-hidden;
    display: flex;
    justify-content: flex-end;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 999;
    transition: $transition;

    &::before {
        content: "";
        background-color: var(--color-tertiary);
        opacity: 0;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 0;
        transition: $transition;
    }

    &__close {
        @extend %flex-center-center;
        background-color: var(--color-primary-4);
        border-radius: 4.5rem;
        cursor: pointer;
        position: absolute;
        top: 1rem;
        left: 1rem;
        height: 4.5rem;
        width: 4.5rem;
        transition: $transition;

        &::before {
            @extend %fig-cross;
            color: var(--color-secondary);
            font-size: 1.6rem;
            transition: $transition;
        }

        &:hover, &:focus-visible {
            background-color: var(--color-secondary);
            &::before { color: #fff; }
        }
    }

    &__content {
        @extend %new-stack;
        @extend %is-hidden;
        background-color: #fff;
        display: flex;
        flex-flow: column nowrap;
        justify-content: space-between;
        padding-top: 5rem;
        padding-bottom: 3rem;
        position: relative;
        overflow-y: scroll;
        overflow-x: hidden;
        width: 55rem;
        height: 100%;
        z-index: 1;
        transform: translateX(100%);
        transition: $transition;

        &::-webkit-scrollbar-track {
            background: transparent;
        }
        &::-webkit-scrollbar-thumb {
            background: var(--color-secondary);
        }

        // &::before {
        //     content: "";
        //     background: url('/storage/svg/african-line-overlay-whitish.svg') center top;
        //     background-size: contain;
        //     position: absolute;
        //     top: 1rem;
        //     right: calc(100% + 1.5rem);
        //     width: 4.3rem;
        //     height: 100%;
        //     z-index: -1;
        // }

        &::after {
            @include before-after-background(
                $url: '/storage/svg/leaf-overlay-1.svg',
                $top: -4rem,
                $right: -2rem,
                $width: 33.4rem,
                $height: 34.2rem,
            );
            transform: scaleX(-1);
            z-index: -1;
        }
    }

    &__top {
        flex-shrink: 1;
    }
    &__bottom {
        flex-shrink: 0;
    }

    &__title {
        @extend %h3;
        letter-spacing: .08em;
        margin-bottom: .5rem;
        padding: 0 3rem;
        text-align: center;
    }

    &__sub {
        @extend %flex-center-center;
        font-size: 1.8rem;
        margin-bottom: 3rem;
        padding: 0 3rem;

        span {
            background-color: var(--color-secondary);
            border-radius: 99rem;
            color: #fff;
            display: inline-block;
            font-size: 1.7rem;
            font-weight: bold;
            line-height: 1.6;
            margin: 0 .75rem;
            padding: 0 .75rem;
            text-align: center;
            white-space: nowrap;
        }
    }

    &__list {
        position: relative;
        overflow-y: scroll;
        height: calc(100vh - 55rem);
        min-height: 15rem;
        max-height: 40rem;

        &::-webkit-scrollbar-track {
            background: transparent;
        }

        &__item {
            border-top: 1px solid var(--color-primary-4);
            display: flex;
            gap: 2rem;
            padding: 1.5rem 3rem;
            position: relative;

            .action {
                background-color: transparent;
                border: none;
                color: var(--color-tertiary);
                cursor: pointer;
                font-size: 2.5rem;
                position: absolute;
                top: 1.5rem;
                right: 3rem;
                transition: $transition;

                &:hover, &:focus-within {
                    color: var(--color-primary);
                }
            }
            .image {
                a {
                    background-color: var(--color-secondary-2);
                    border-radius: 99rem;
                    display: inline-flex;
                    justify-content: center;
                    align-items: center;
                    flex-shrink: 0;
                    padding: .5rem;
                    position: relative;
                    height: 8rem;
                    width: 8rem;
                }
            }
            .image img {
                display: inline-block;
                max-height: 90%;
                max-width: 80%;
            }
            .image span {
                @extend %flex-center-center;
                background-color: var(--color-primary);
                border-radius: 3rem;
                color: #fff;
                font-size: 2rem;
                font-weight: bold;
                position: absolute;
                top: 0;
                right: 0;
                height: 3rem;
                width: 3rem;
            }
            .title {
                color: var(--color-secondary);
                display: block;
                font-size: 2rem;
                font-weight: bold;
                padding-right: 3rem;
                text-transform: uppercase;
            }
            .sub {
                font-size: 2rem;
            }
            .price {
                color: var(--color-primary);
                font-size: 2.5rem;
                font-weight: bold;
                line-height: 1;
            }
        }
    }

    &__summary {
        @extend %flex-center-between;
        margin-bottom: .5rem;
        padding: 10rem 3rem 0 3rem;
        position: relative;

        &::before {
            @include before-after-background(
                $url: '/storage/svg/african-line-overlay-1-secondary-white.svg',
                $top: 0,
                $left: 0,
                $width: 100%,
                $height: 9.3rem,
            );
        }

        & > span:first-child {
            font-size: 2rem;
        }
        & > span:last-child {
            color: var(--color-primary);
            font-size: 4rem;
            font-weight: bold;
            text-align: right;
        }
    }

    &__actions {
        display: flex;
        flex-flow: column nowrap;
        gap: 1rem;
        padding: 0 3rem;
    }
}
.side-panel.active {
    @extend %is-visible;
    &::before {
        opacity: .85;
    }
    .side-panel__content {
        @extend %is-visible;
        transform: translateX(0);
    }
}
.side-panel--wishlist {
    .side-panel__list {
        min-height: 0;
        max-height: none;
        height: 100%;
    }
}