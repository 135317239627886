.contact-block {
    text-align: center;
    &__icon {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        background-color: var(--color-secondary);
        border-radius: 99rem;
        color: #fff;
        font-size: 3rem;
        margin-bottom: 1.5rem;
        height: 11rem;
        width: 8rem;
    }

    &__title {
        color: var(--color-tertiary);
        font-size: 2rem;
        font-weight: bold;
        margin-bottom: .5rem;
    }

    &__value {
        font-size: 2.3rem;
    }
}