.page-product-single {
    padding-top: 8rem;
}

.product-single-top {
    margin-bottom: 12rem;

    .container {
        @include create-grid-columns-custom(
            $columns: minmax(45rem, 1fr) 1.8fr,
            $grid-gap: clamp-calc(120rem, $grid-width-default, 2rem, 8rem),
        );
    }
}

.product-single-infos {
    @extend .section-references;
    overflow: visible;
    padding-top: 0;

    .collapse {
        &__tabs {
            transform: translateY(-50%);
        }
    }
}

.product-single {

    &__title {
        color: var(--color-secondary);
        font-size: 5.5rem;
        font-weight: normal;
        margin-bottom: 1.5rem;
        text-transform: uppercase;
    }

    &__price {
        margin-bottom: 2rem;

        span {
            color: var(--color-primary);
            display: inline-block;
            font-size: 7rem;
            font-weight: bold;
            position: relative;

            &::after {
                content: "";
                background-color: var(--color-primary);
                position: absolute;
                top: 50%;
                left: calc(100% + 5rem);
                height: 3px;
                width: 100vw;
            }
        }
    }

}
.product-single-slider__first {
    @extend %flex-start-center;
    @extend %new-stack;
    aspect-ratio: 1;
    margin: 0 auto;
    max-height: 50rem;

    &::before {
        content: "";
        aspect-ratio: 1;
        background-color: var(--color-secondary-2);
        border-radius: 99rem;
        position: absolute;
        bottom: 0;
        left: 50%;
        width: 90%;
        z-index: -1;
        transform: translateX(-50%);
    }

    img {
        max-width: 90%;
        max-height: 42rem;
    }
}
.product-single-slider__item {
    aspect-ratio: 1;
    border-radius: 999rem;
    display: block;
    overflow: hidden;
    margin: 0 auto;
    max-height: 50rem;
    img { @extend %image-cover; }
}