@charset "UTF-8";
/**
 * ------------------------------------------------
 * CSS Variables
 * ------------------------------------------------
 */
@import url("https://fonts.googleapis.com/css2?family=Karla:ital,wght@0,300;0,400;0,500;0,700;1,300&display=swap");
:root {
  --color-primary: #D0A346;
  --color-primary-2: #C1A55C;
  --color-primary-3: #DCC18C;
  --color-primary-4: #F1E8D4;
  --color-secondary: #bc6a42;
  --color-secondary-2: #FAF4F2;
  --color-tertiary: #4B2A1A;
  --color-tertiary-2: #E9CDBF;
  --color-dark: #000;
  --color-grey: #B2A893;
  --color-grey-2: #888;
  --color-grey-3: #F5F5F5;
  --color-white: #fff;
  --color-danger: #c93d30;
  --color-success: #27ae60;
  --font-family-1st: "Karla", sans-serif;
  --font-family-2nd: "Luxerie Regular", sans-serif;
  --font-family-3rd: "Roustel Regular", sans-serif;
}

/**
 * ------------------------------------------------
 * SASS Config Variables
 * ------------------------------------------------
 */
/* -----------------------------------------------
 *  Background mixin extend
 ------------------------------------------------- */
/* -----------------------------------------------
 *  Button mixin extend
 ------------------------------------------------- */
/* -----------------------------------------------
 *  Font icon mixin extend
 ------------------------------------------------- */
/* -----------------------------------------------
 *  Flex mixin extend
 ------------------------------------------------- */
/* -----------------------------------------------
 *  Grid mixin extend
 ------------------------------------------------- */
/* -----------------------------------------------
 *  Hover mixin extend
 ------------------------------------------------- */
/* -----------------------------------------------
 *  Icon mixin extend
 ------------------------------------------------- */
/* -----------------------------------------------
 *  Image mixin extend
 ------------------------------------------------- */
/* -----------------------------------------------
 *  Pellet mixin extend
 ------------------------------------------------- */
/* -----------------------------------------------
 *  Section mixin extend
 ------------------------------------------------- */
/* -----------------------------------------------
 *  Title mixin extend
 ------------------------------------------------- */
/* -----------------------------------------------
 *  Visibility mixin extend
 ------------------------------------------------- */
/* -----------------------------------------------
 *  Z-index mixin extend
 ------------------------------------------------- */
/* -----------------------------------------------
 *  CUSTOM mixin extend
 ------------------------------------------------- */
/* -----------------------------------------------
 *  Grid creation
 ------------------------------------------------- */
/* -----------------------------------------------
 *  Grid edit
 ------------------------------------------------- */
/* -----------------------------------------------
 *  Grid helpers
 ------------------------------------------------- */
/* -----------------------------------------------
 *  Background extend
 ------------------------------------------------- */
.section-banner__slide__overlay-1 {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

/* -----------------------------------------------
 *  Button extend
 ------------------------------------------------- */
.header__button, .button-white-outline, .button-tertiary-outline, .button-tertiary, .form-group-button button, .button-secondary-outline, .button-secondary-2, .button-primary-outline, .button-primary {
  border: 2px solid transparent;
  cursor: pointer;
  font-size: 2.5rem;
  font-weight: 500;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  flex-flow: row wrap;
  gap: 1.5rem;
  padding: 2rem 4rem;
  text-align: center;
  text-transform: uppercase;
  transition: 0.4s;
}

.carousel-navigations .prev, .carousel-navigations .next, .button-icon-secondary-h, .button-icon-primary, .qty__remove, .qty__add {
  border: 2px solid transparent;
  border-radius: 5.8rem;
  cursor: pointer;
  width: 5.8rem;
  height: 5.8rem;
  transition: 0.4s;
}

/* -----------------------------------------------
 *  Font icon extend
 ------------------------------------------------- */
.fig-user-add::before, .fig-user::before, .fig-trash::before, .fig-tags::before, .fig-shopping-cart-add::before, .fig-shopping-cart::before, .fig-quote-right::before, .qty__add::before, .fig-plus-small::before, .fig-phone-call::before, .qty__remove::before, .fig-minus-small::before, .fig-map-marker::before, .button-loading::before, .fig-loading::before, .fig-leaf::before, .fig-globe::before, .fig-home::before, .fig-heart::before, .see-password-wrap .see-password::before, .fig-eye-crossed::before, .see-password-wrap .see-password.active::before, .fig-eye::before, .side-panel__close::before, .menu-overlay__close::before, .alert-container .alert__close::before, .fig-cross::before, .checkbox-group .label::before, .fig-check::before, .carousel-navigations-2 .next::before, .carousel-navigations .next::before, .fig-angle-right::before, .carousel-navigations-2 .prev::before, .carousel-navigations .prev::before, .fig-angle-left::before, .scroll-top::before, .fig-arrow-to-top::before, .fig-arrow-right::before, .fig-arrow-left::before {
  display: inline-block;
  font-family: uicons-regular-rounded !important;
  font-style: normal;
  font-weight: normal !important;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transform: translateY(0.09em);
}

.fib-twitter::before, .fib-instagram::before, .fib-facebook::before {
  display: inline-block;
  font-family: uicons-brands !important;
  font-style: normal;
  font-weight: normal !important;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transform: translateY(1px);
}

/* -----------------------------------------------
 *  Flex extend
 ------------------------------------------------- */
.reference {
  display: flex;
  align-items: flex-start;
}

.page-header__title, .header__actions, .menu-overlay__navigation ul, .product-card__buttons, .product-card__actions, .product-card__tags, .product-card, .carousel-navigations, .btn-wrap, .alert-container .alert {
  display: flex;
  align-items: center;
}

.product-single-slider__first {
  display: flex;
  align-items: flex-start;
  justify-content: center;
}

.thanks-img i, .section-intro__block-1, .footer__menu-small ul, .footer__menu ul, .footer__socials ul, .side-panel__list__item .image span, .cart__item .image span, .side-panel__sub, .side-panel__close, .scroll-top, .menu-overlay__navigation li, .quote__author, .quote__icon, .product-card__image, .language-switcher__current, .carousel-navigations-2 .pagination, .carousel-navigations-2 .prev, .carousel-navigations-2 .next, .carousel-navigations-2, .button-action__pellet, .button-action, .section-title--1 .section-title__icon, .qty, .carousel-navigations .prev, .carousel-navigations .next, .button-icon-secondary-h, .button-icon-primary, .qty__remove, .qty__add {
  display: flex;
  align-items: center;
  justify-content: center;
}

.section-banner__slide .right {
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

.stable-price__item, .side-panel__summary {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.header .container {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

/* -----------------------------------------------
 *  Grid extend
 ------------------------------------------------- */
.page-login, .section-banner__slide, .page-ecommerce, .page-dashboard, .section-grid, .section, .header, .menu-overlay__content {
  display: grid;
  grid-template-columns: minmax(2.5rem, 1fr) minmax(0, 150rem) minmax(2.5rem, 1fr);
}
.page-login > *, .section-banner__slide > *, .page-ecommerce > *, .page-dashboard > *, .section-grid > *, .section > *, .header > *, .menu-overlay__content > * {
  grid-column: 2/-2;
}

.page-login .container, .section-intro .container, .page-contact__infos .container, .menu-overlay .container {
  display: grid;
  grid-gap: 2.5rem;
}

.row-2c {
  display: grid;
  grid-column-gap: 2rem;
}

/* -----------------------------------------------
 *  Hover extend
 ------------------------------------------------- */
/* -----------------------------------------------
 *  Icon extend
 ------------------------------------------------- */
/* -----------------------------------------------
 *  Image extend
 ------------------------------------------------- */
.thanks-img img, .product-single-slider__item img, .card-image__image img {
  object-fit: cover;
  height: 100%;
  width: 100%;
}

/* -----------------------------------------------
 *  Link extend
 ------------------------------------------------- */
.link {
  color: var(--color-primary);
  display: inline-block;
  font-size: inherit;
  font-weight: bold;
  position: relative;
}
.link::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  height: 2px;
  width: 0;
  transition: 0.4s;
}
.link:hover::after, .link:focus-visible::after {
  background-color: var(--color-primary);
  width: 100%;
}

/* -----------------------------------------------
 *  Pellet extend
 ------------------------------------------------- */
/* -----------------------------------------------
 *  Section extend
 ------------------------------------------------- */
.section {
  position: relative;
}

/* -----------------------------------------------
 *  Stacking extend
 ------------------------------------------------- */
.product-single-slider__first, .page-login .content_wrap, .page-login .content, .section-references, .product-single-infos, .section-quote, .section-respect, .section-products, .section-banner .banner-title span:last-child, .section-banner__slide .right, .section-banner__slide .left, .page-ecommerce .right__content, .page-ecommerce .right, .page-header, .side-panel__content, .menu-overlay .left, .menu-overlay__content, .quote__author__image, .product-card__image, .card--1, .reference__content, .card-image__content {
  position: relative;
  isolation: isolate;
}

/* -----------------------------------------------
 *  Title extend
 ------------------------------------------------- */
h6, .h6, h5, .h5, .card-simple__textFeat, h4, .h4, .fieldset-1 legend, .side-panel__title, .section-title--1 .section-title__sup, h3, .h3, .page-header__title, .reference__title, h2, .h2, .section-title__main, h1, .h1 {
  color: var(--color-primary);
  font-family: var(--font-family-2nd);
  font-weight: normal;
  line-height: 1;
  text-transform: uppercase;
}

.section-title__main, h1, .h1 {
  font-size: 10rem;
  margin-bottom: 4rem;
}

.page-header__title, .reference__title, h2, .h2 {
  font-size: 7rem;
  margin-bottom: 1.5rem;
}

.side-panel__title, .section-title--1 .section-title__sup, h3, .h3 {
  font-size: 5rem;
  margin-bottom: 1.5rem;
}

h4, .h4, .fieldset-1 legend {
  font-size: 4.2rem;
  letter-spacing: 0.02em;
  margin-bottom: 1.5rem;
}

h5, .h5, .card-simple__textFeat {
  font-size: 2.3rem;
  letter-spacing: 0.05em;
  margin-bottom: 1rem;
}

h6, .h6 {
  font-size: 1.8rem;
  letter-spacing: 0.05em;
  margin-bottom: 1rem;
}

/* -----------------------------------------------
 *  Visibility extend
 ------------------------------------------------- */
.wow, .side-panel__content, .side-panel, .scroll-top, .menu-overlay .left, .menu-overlay .right, .menu-overlay, .language-switcher__dropdown, .button-loading.is-loading > *, .button-loading::before, .coupon-code__input, .link::after {
  opacity: 0;
  visibility: hidden;
}

.side-panel.active .side-panel__content, .side-panel.active, .scroll-top.active, .menu-overlay.active .left, .menu-overlay.active .right, .menu-overlay.active, .language-switcher:hover .language-switcher__dropdown, .button-loading.is-loading::before, .coupon-code.active .coupon-code__input, .link:hover::after, .link:focus-visible::after {
  opacity: 1;
  visibility: visible;
}

/* -----------------------------------------------
 *  Z-index extend
 ------------------------------------------------- */
/* -----------------------------------------------
 *  Auto generated extends
 * -> You should not modify that part
 ------------------------------------------------- */
.u-mb-0 {
  margin-bottom: 0 !important;
}

.u-mb-5 {
  margin-bottom: calc(5rem/10) !important;
}

.u-mb-10 {
  margin-bottom: calc(10rem/10) !important;
}

.u-mt-10 {
  margin-top: calc(10rem/10) !important;
}

.u-ml-15 {
  margin-left: calc(15rem/10) !important;
}

.u-mb-20 {
  margin-bottom: calc(20rem/10) !important;
}

.u-mb-30 {
  margin-bottom: calc(30rem/10) !important;
}

.u-mb-50 {
  margin-bottom: calc(50rem/10) !important;
}

.u-mb-70 {
  margin-bottom: calc(70rem/10) !important;
}

/* -----------------------------------------------
 *  Google fonts
 ------------------------------------------------- */
/* -----------------------------------------------
 *  External fonts
 ------------------------------------------------- */
@font-face {
  font-family: "Luxerie Regular";
  src: url("/themes/biokide/fonts/Luxerie-Regular.woff2") format("woff2"), url("/themes/biokide/fonts/Luxerie-Regular.woff") format("woff");
  font-display: swap;
}
@font-face {
  font-family: "Roustel Regular";
  src: url("/themes/biokide/fonts/Roustel-Regular.woff2") format("woff2"), url("/themes/biokide/fonts/Roustel-Regular.woff") format("woff");
  font-display: swap;
}
/* -----------------------------------------------
 *  Uicons fonts

    -> If you want to use an icon, you have to copy and paste this one in the appropriate SASS array
        You can then extend this font with its name.
        ° Examples: "add-folder" is a "General Icon" and can be used as "@extend %fig-add-folder" and also "<i class="fig-add-folder"></i>"
                    "facebook" is a "Brand Icon" and can be used as "@extend %fib-facebook" and also "<i class="fib-facebook"></i>"


    >>> GENERAL ICONS ($uicons-fonts)
    "a":"\f101",
    "ad":"\f102",
    "add-document":"\f103",
    "add-folder":"\f104",
    "add":"\f105",
    "address-book":"\f106",
    "air-conditioner":"\f107",
    "air-freshener":"\f108",
    "alarm-clock":"\f109",
    "alarm-exclamation":"\f10a",
    "alarm-plus":"\f10b",
    "alarm-snooze":"\f10c",
    "align-justify":"\f10d",
    "align-left":"\f10e",
    "ambulance":"\f10f",
    "angle-circle-down":"\f110",
    "angle-circle-left":"\f111",
    "angle-circle-right":"\f112",
    "angle-circle-up":"\f113",
    "angle-double-left":"\f114",
    "angle-double-right":"\f115",
    "angle-double-small-down":"\f116",
    "angle-double-small-left":"\f117",
    "angle-double-small-right":"\f118",
    "angle-double-small-up":"\f119",
    "angle-down":"\f11a",
    "angle-left":"\f11b",
    "angle-right":"\f11c",
    "angle-small-down":"\f11d",
    "angle-small-left":"\f11e",
    "angle-small-right":"\f11f",
    "angle-small-up":"\f120",
    "angle-square-down":"\f121",
    "angle-square-left":"\f122",
    "angle-square-right":"\f123",
    "angle-square-up":"\f124",
    "angle-up":"\f125",
    "angry":"\f126",
    "apple-whole":"\f127",
    "apps-add":"\f128",
    "apps-delete":"\f129",
    "apps-sort":"\f12a",
    "apps":"\f12b",
    "archive":"\f12c",
    "arrow-alt-circle-down":"\f12d",
    "arrow-alt-circle-left":"\f12e",
    "arrow-alt-circle-right":"\f12f",
    "arrow-alt-circle-up":"\f130",
    "arrow-alt-down":"\f131",
    "arrow-alt-from-bottom":"\f132",
    "arrow-alt-from-left":"\f133",
    "arrow-alt-from-right":"\f134",
    "arrow-alt-from-top":"\f135",
    "arrow-alt-left":"\f136",
    "arrow-alt-right":"\f137",
    "arrow-alt-square-down":"\f138",
    "arrow-alt-square-left":"\f139",
    "arrow-alt-square-right":"\f13a",
    "arrow-alt-square-up":"\f13b",
    "arrow-alt-to-bottom":"\f13c",
    "arrow-alt-to-left":"\f13d",
    "arrow-alt-to-right":"\f13e",
    "arrow-alt-to-top":"\f13f",
    "arrow-alt-up":"\f140",
    "arrow-circle-down":"\f141",
    "arrow-circle-left":"\f142",
    "arrow-circle-right":"\f143",
    "arrow-circle-up":"\f144",
    "arrow-down":"\f145",
    "arrow-from-bottom":"\f146",
    "arrow-from-left":"\f147",
    "arrow-from-right":"\f148",
    "arrow-from-top":"\f149",
    "arrow-left":"\f14a",
    "arrow-right":"\f14b",
    "arrow-small-down":"\f14c",
    "arrow-small-left":"\f14d",
    "arrow-small-right":"\f14e",
    "arrow-small-up":"\f14f",
    "arrow-square-down":"\f150",
    "arrow-square-left":"\f151",
    "arrow-square-right":"\f152",
    "arrow-square-up":"\f153",
    "arrow-to-bottom":"\f154",
    "arrow-to-left":"\f155",
    "arrow-to-right":"\f156",
    "arrow-to-top":"\f157",
    "arrow-up":"\f158",
    "arrows-alt-h":"\f159",
    "arrows-alt-v":"\f15a",
    "arrows-alt":"\f15b",
    "arrows-h-copy":"\f15c",
    "arrows-h":"\f15d",
    "arrows":"\f15e",
    "assept-document":"\f15f",
    "assistive-listening-systems":"\f160",
    "asterik":"\f161",
    "at":"\f162",
    "aubergine":"\f163",
    "avocado":"\f164",
    "b":"\f165",
    "baby-carriage":"\f166",
    "backpack":"\f167",
    "bacon":"\f168",
    "badge-sheriff":"\f169",
    "badge-check":"\f16a",
    "badge-dollar":"\f16b",
    "badge-percent":"\f16c",
    "badge":"\f16d",
    "bags-shopping":"\f16e",
    "bahai":"\f16f",
    "balance-scale-left":"\f170",
    "balance-scale-right":"\f171",
    "ball":"\f172",
    "balloons":"\f173",
    "ballot":"\f174",
    "ban":"\f175",
    "band-aid":"\f176",
    "bank":"\f177",
    "barber-shop":"\f178",
    "bars-progress":"\f179",
    "baseball-alt":"\f17a",
    "baseball":"\f17b",
    "basket":"\f17c",
    "basketball":"\f17d",
    "bed-alt":"\f17e",
    "bed":"\f17f",
    "beer":"\f180",
    "bell-ring":"\f181",
    "bell-school":"\f182",
    "bell":"\f183",
    "bike":"\f184",
    "biking-mountain":"\f185",
    "biking":"\f186",
    "billiard":"\f187",
    "blinds-open":"\f188",
    "blinds-raised":"\f189",
    "blinds":"\f18a",
    "bold":"\f18b",
    "bolt":"\f18c",
    "book-alt":"\f18d",
    "book-arrow-right":"\f18e",
    "book-arrow-up":"\f18f",
    "book-bookmark":"\f190",
    "book-open-cover":"\f191",
    "book-open-reader":"\f192",
    "book":"\f193",
    "bookmark":"\f194",
    "books":"\f195",
    "bottle":"\f196",
    "bow-arrow":"\f197",
    "bowling-ball":"\f198",
    "bowling-pins":"\f199",
    "bowling":"\f19a",
    "box-check":"\f19b",
    "box-tissue":"\f19c",
    "box-alt":"\f19d",
    "box-ballot":"\f19e",
    "box":"\f19f",
    "boxes":"\f1a0",
    "boxing-glove":"\f1a1",
    "braille":"\f1a2",
    "bread-slice":"\f1a3",
    "bread":"\f1a4",
    "briefcase":"\f1a5",
    "broccoli":"\f1a6",
    "broom":"\f1a7",
    "browser":"\f1a8",
    "brush":"\f1a9",
    "bug":"\f1aa",
    "building":"\f1ab",
    "bulb":"\f1ac",
    "bullseye-pointer":"\f1ad",
    "bullseye":"\f1ae",
    "burrito":"\f1af",
    "bus-alt":"\f1b0",
    "bus":"\f1b1",
    "business-time":"\f1b2",
    "butterfly":"\f1b3",
    "cake-birthday":"\f1b4",
    "cake-wedding":"\f1b5",
    "calculator":"\f1b6",
    "calendar-check":"\f1b7",
    "calendar-clock":"\f1b8",
    "calendar-exclamation":"\f1b9",
    "calendar-lines-pen":"\f1ba",
    "calendar-lines":"\f1bb",
    "calendar-minus":"\f1bc",
    "calendar-pen":"\f1bd",
    "calendar-plus":"\f1be",
    "calendar":"\f1bf",
    "calendars":"\f1c0",
    "call-history":"\f1c1",
    "call-incoming":"\f1c2",
    "call-missed":"\f1c3",
    "call-outgoing":"\f1c4",
    "camcorder":"\f1c5",
    "camera":"\f1c6",
    "camping":"\f1c7",
    "candy-alt":"\f1c8",
    "candy":"\f1c9",
    "canned-food":"\f1ca",
    "car-bolt":"\f1cb",
    "car-circle-bolt":"\f1cc",
    "car-side-bolt":"\f1cd",
    "car-alt":"\f1ce",
    "car-battery":"\f1cf",
    "car-building":"\f1d0",
    "car-bump":"\f1d1",
    "car-bus":"\f1d2",
    "car-crash":"\f1d3",
    "car-garage":"\f1d4",
    "car-mechanic":"\f1d5",
    "car-side":"\f1d6",
    "car-tilt":"\f1d7",
    "car-wash":"\f1d8",
    "car":"\f1d9",
    "caravan-alt":"\f1da",
    "caravan":"\f1db",
    "caret-circle-down":"\f1dc",
    "caret-circle-right":"\f1dd",
    "caret-circle-up":"\f1de",
    "caret-down":"\f1df",
    "caret-left":"\f1e0",
    "caret-quare-up":"\f1e1",
    "caret-right":"\f1e2",
    "caret-square-down":"\f1e3",
    "caret-square-left_1":"\f1e4",
    "caret-square-left":"\f1e5",
    "caret-square-right":"\f1e6",
    "caret-up":"\f1e7",
    "carrot":"\f1e8",
    "cars":"\f1e9",
    "cash-register":"\f1ea",
    "chair-office":"\f1eb",
    "chair":"\f1ec",
    "charging-station":"\f1ed",
    "chart-area":"\f1ee",
    "chart-connected":"\f1ef",
    "chart-histogram":"\f1f0",
    "chart-line-up":"\f1f1",
    "chart-network":"\f1f2",
    "chart-pie-alt":"\f1f3",
    "chart-pie":"\f1f4",
    "chart-pyramid":"\f1f5",
    "chart-scatter":"\f1f6",
    "chart-set-theory":"\f1f7",
    "chart-tree":"\f1f8",
    "chat-arrow-down":"\f1f9",
    "chat-arrow-grow":"\f1fa",
    "check":"\f1fb",
    "checkbox":"\f1fc",
    "cheese":"\f1fd",
    "cherry":"\f1fe",
    "chess-piece":"\f1ff",
    "chess-bishop":"\f200",
    "chess-board":"\f201",
    "chess-clock-alt":"\f202",
    "chess-clock":"\f203",
    "chess-king-alt":"\f204",
    "chess-king":"\f205",
    "chess-knight-alt":"\f206",
    "chess-knight":"\f207",
    "chess-pawn-alt":"\f208",
    "chess-queen-alt":"\f209",
    "chess-queen":"\f20a",
    "chess-rook-alt":"\f20b",
    "chess-rook":"\f20c",
    "chess":"\f20d",
    "chevron-double-down":"\f20e",
    "chevron-double-up":"\f20f",
    "child-head":"\f210",
    "chocolate":"\f211",
    "circle-phone":"\f212",
    "circle-envelope":"\f213",
    "circle-phone-flip":"\f214",
    "circle-phone-hangup":"\f215",
    "circle-small":"\f216",
    "circle":"\f217",
    "clip":"\f218",
    "clipboard-list":"\f219",
    "clipboard-list-check":"\f21a",
    "clock-eight-thirty":"\f21b",
    "clock-eleven-thirty":"\f21c",
    "clock-eleven":"\f21d",
    "clock-five-thirty":"\f21e",
    "clock-five":"\f21f",
    "clock-four-thirty":"\f220",
    "clock-nine-thirty":"\f221",
    "clock-nine":"\f222",
    "clock-one-thirty":"\f223",
    "clock-one":"\f224",
    "clock-seven-thirty":"\f225",
    "clock-seven":"\f226",
    "clock-six-thirty":"\f227",
    "clock-six":"\f228",
    "clock-ten-thirty":"\f229",
    "clock-ten":"\f22a",
    "clock-three-thirty":"\f22b",
    "clock-three":"\f22c",
    "clock-twelve-thirty":"\f22d",
    "clock-twelve":"\f22e",
    "clock-two-thirty":"\f22f",
    "clock-two":"\f230",
    "clock":"\f231",
    "cloud-upload":"\f232",
    "cloud-check":"\f233",
    "cloud-disabled":"\f234",
    "cloud-download-alt":"\f235",
    "cloud-download":"\f236",
    "cloud-drizzle":"\f237",
    "cloud-hail-mixed":"\f238",
    "cloud-hail":"\f239",
    "cloud-moon-rain":"\f23a",
    "cloud-moon":"\f23b",
    "cloud-rain":"\f23c",
    "cloud-rainbow":"\f23d",
    "cloud-share":"\f23e",
    "cloud-showers-heavy":"\f23f",
    "cloud-showers":"\f240",
    "cloud-sleet":"\f241",
    "cloud-snow":"\f242",
    "cloud-sun-rain":"\f243",
    "cloud-sun":"\f244",
    "cloud-upload-alt":"\f245",
    "cloud":"\f246",
    "clouds-moon":"\f247",
    "clouds-sun":"\f248",
    "clouds":"\f249",
    "club":"\f24a",
    "cocktail-alt":"\f24b",
    "cocktail":"\f24c",
    "coffee-pot":"\f24d",
    "coffee":"\f24e",
    "coin":"\f24f",
    "coins":"\f250",
    "comet":"\f251",
    "comment-alt-middle-top":"\f252",
    "comment-alt-middle":"\f253",
    "comment-alt":"\f254",
    "comment-arrow-down":"\f255",
    "comment-arrow-up-right":"\f256",
    "comment-arrow-up":"\f257",
    "comment-check":"\f258",
    "comment-code":"\f259",
    "comment-dollar":"\f25a",
    "comment-exclamation":"\f25b",
    "comment-heart":"\f25c",
    "comment-image":"\f25d",
    "comment-info":"\f25e",
    "comment-pen":"\f25f",
    "comment-question":"\f260",
    "comment-quote":"\f261",
    "comment-slash":"\f262",
    "comment-sms":"\f263",
    "comment-text":"\f264",
    "comment-user":"\f265",
    "comment-xmark":"\f266",
    "comment":"\f267",
    "comments-dollar":"\f268",
    "comments-question-check":"\f269",
    "comments-question":"\f26a",
    "comments":"\f26b",
    "compress-alt":"\f26c",
    "compress":"\f26d",
    "computer":"\f26e",
    "confetti":"\f26f",
    "cookie":"\f270",
    "copy-alt":"\f271",
    "copy":"\f272",
    "copyright":"\f273",
    "corn":"\f274",
    "cow":"\f275",
    "cream":"\f276",
    "credit-card":"\f277",
    "cricket":"\f278",
    "croissant":"\f279",
    "cross-circle":"\f27a",
    "cross-small":"\f27b",
    "cross":"\f27c",
    "crown":"\f27d",
    "crystal-ball":"\f27e",
    "cube":"\f27f",
    "cupcake":"\f280",
    "curling":"\f281",
    "cursor-finger":"\f282",
    "cursor-plus":"\f283",
    "cursor-text-alt":"\f284",
    "cursor-text":"\f285",
    "cursor":"\f286",
    "d":"\f287",
    "dart":"\f288",
    "dashboard":"\f289",
    "data-transfer":"\f28a",
    "database":"\f28b",
    "delete-document":"\f28c",
    "delete-user":"\f28d",
    "delete":"\f28e",
    "democrat":"\f28f",
    "dewpoint":"\f290",
    "diamond":"\f291",
    "dice-alt":"\f292",
    "dice-d10":"\f293",
    "dice-d12":"\f294",
    "dice-d20":"\f295",
    "dice-d4":"\f296",
    "dice-d6":"\f297",
    "dice-d8":"\f298",
    "dice-four":"\f299",
    "dice-one":"\f29a",
    "dice-six":"\f29b",
    "dice-three":"\f29c",
    "dice-two":"\f29d",
    "dice":"\f29e",
    "diploma":"\f29f",
    "disco-ball":"\f2a0",
    "disk":"\f2a1",
    "dizzy":"\f2a2",
    "doctor":"\f2a3",
    "document-signed":"\f2a4",
    "document":"\f2a5",
    "dollar":"\f2a6",
    "donate":"\f2a7",
    "down-left-and-up-right-to-center":"\f2a8",
    "download":"\f2a9",
    "drafting-compass":"\f2aa",
    "dreidel":"\f2ab",
    "drink-alt":"\f2ac",
    "drumstick":"\f2ad",
    "duplicate":"\f2ae",
    "e-learning":"\f2af",
    "e":"\f2b0",
    "earnings":"\f2b1",
    "eclipse-alt":"\f2b2",
    "eclipse":"\f2b3",
    "edit-alt":"\f2b4",
    "edit":"\f2b5",
    "egg-fried":"\f2b6",
    "egg":"\f2b7",
    "engine-warning":"\f2b8",
    "enter":"\f2b9",
    "envelope-ban":"\f2ba",
    "envelope-bulk":"\f2bb",
    "envelope-download":"\f2bc",
    "envelope-marker":"\f2bd",
    "envelope-open-dollar":"\f2be",
    "envelope-open-text":"\f2bf",
    "envelope-open":"\f2c0",
    "envelope-plus":"\f2c1",
    "envelope":"\f2c2",
    "equality":"\f2c3",
    "euro":"\f2c4",
    "exchange-alt":"\f2c5",
    "exchange":"\f2c6",
    "exclamation":"\f2c7",
    "exit":"\f2c8",
    "expand-arrows-alt":"\f2c9",
    "expand-arrows":"\f2ca",
    "expand":"\f2cb",
    "eye-crossed":"\f2cc",
    "eye-dropper":"\f2cd",
    "eye":"\f2ce",
    "f":"\f2cf",
    "feather":"\f2d0",
    "ferris-wheel":"\f2d1",
    "fighter-jet":"\f2d2",
    "file-invoice-dollar":"\f2d3",
    "file-invoice":"\f2d4",
    "file-ai":"\f2d5",
    "file-chart-line":"\f2d6",
    "file-chart-pie":"\f2d7",
    "file-eps":"\f2d8",
    "file-psd":"\f2d9",
    "file-spreadsheet":"\f2da",
    "file-video":"\f2db",
    "file":"\f2dc",
    "fill":"\f2dd",
    "film-slash":"\f2de",
    "film":"\f2df",
    "filter-slash":"\f2e0",
    "filter":"\f2e1",
    "filters":"\f2e2",
    "fingerprint":"\f2e3",
    "fish":"\f2e4",
    "flag-alt":"\f2e5",
    "flag-usa":"\f2e6",
    "flag":"\f2e7",
    "flame":"\f2e8",
    "flip-horizontal":"\f2e9",
    "flower-bouquet":"\f2ea",
    "flower-tulip":"\f2eb",
    "flower":"\f2ec",
    "flushed":"\f2ed",
    "fog":"\f2ee",
    "folder-download":"\f2ef",
    "folder-minus":"\f2f0",
    "folder-times":"\f2f1",
    "folder-tree":"\f2f2",
    "folder-upload":"\f2f3",
    "folder":"\f2f4",
    "folders":"\f2f5",
    "following":"\f2f6",
    "football":"\f2f7",
    "fork":"\f2f8",
    "form":"\f2f9",
    "forward":"\f2fa",
    "fox":"\f2fb",
    "french-fries":"\f2fc",
    "frown":"\f2fd",
    "ftp":"\f2fe",
    "funnel-dollar":"\f2ff",
    "g":"\f300",
    "gallery":"\f301",
    "game-board-alt":"\f302",
    "gamepad":"\f303",
    "garage-car":"\f304",
    "garage-open":"\f305",
    "garage":"\f306",
    "garlic":"\f307",
    "gas-pump-alt":"\f308",
    "gas-pump-slash":"\f309",
    "gas-pump":"\f30a",
    "gem":"\f30b",
    "gif":"\f30c",
    "gift-card":"\f30d",
    "gift":"\f30e",
    "gifts":"\f30f",
    "gingerbread-man":"\f310",
    "glass-cheers":"\f311",
    "glass":"\f312",
    "glasses":"\f313",
    "globe-alt":"\f314",
    "globe":"\f315",
    "golf-ball":"\f316",
    "golf":"\f317",
    "graduation-cap":"\f318",
    "grape":"\f319",
    "graphic-tablet":"\f31a",
    "grid-alt":"\f31b",
    "grid":"\f31c",
    "grill":"\f31d",
    "grimace":"\f31e",
    "grin-alt":"\f31f",
    "grin-beam-sweat":"\f320",
    "grin-beam":"\f321",
    "grin-hearts":"\f322",
    "grin-squint-tears":"\f323",
    "grin-squint":"\f324",
    "grin-stars":"\f325",
    "grin-tears":"\f326",
    "grin-tongue-squint":"\f327",
    "grin-tongue-wink":"\f328",
    "grin-tongue":"\f329",
    "grin-wink":"\f32a",
    "grin":"\f32b",
    "guitar":"\f32c",
    "gym":"\f32d",
    "h":"\f32e",
    "hamburger-soda":"\f32f",
    "hamburger":"\f330",
    "hand-holding-box":"\f331",
    "hand-holding-heart":"\f332",
    "hand-holding-seeding":"\f333",
    "hand":"\f334",
    "handshake":"\f335",
    "hastag":"\f336",
    "hat-birthday":"\f337",
    "hat-chef":"\f338",
    "head-side-thinking":"\f339",
    "headphones":"\f33a",
    "headset":"\f33b",
    "heart-arrow":"\f33c",
    "heart":"\f33d",
    "heat":"\f33e",
    "helicopter-side":"\f33f",
    "highlighter":"\f340",
    "hiking":"\f341",
    "hockey-puck":"\f342",
    "hockey-sticks":"\f343",
    "home-location-alt":"\f344",
    "home-location":"\f345",
    "home":"\f346",
    "hotdog":"\f347",
    "hourglass-end":"\f348",
    "hourglass":"\f349",
    "house-flood":"\f34a",
    "hryvnia":"\f34b",
    "humidity":"\f34c",
    "hurricane":"\f34d",
    "i":"\f34e",
    "ice-cream":"\f34f",
    "ice-skate":"\f350",
    "id-badge":"\f351",
    "inbox-in":"\f352",
    "inbox-out":"\f353",
    "inbox":"\f354",
    "incognito":"\f355",
    "indent":"\f356",
    "infinity":"\f357",
    "info":"\f358",
    "interactive":"\f359",
    "interlining":"\f35a",
    "interrogation":"\f35b",
    "italic":"\f35c",
    "j":"\f35d",
    "jam":"\f35e",
    "jpg":"\f35f",
    "k":"\f360",
    "kerning":"\f361",
    "key":"\f362",
    "keyboard":"\f363",
    "keynote":"\f364",
    "kiss-beam":"\f365",
    "kiss-wink-heart":"\f366",
    "kiss":"\f367",
    "kite":"\f368",
    "knife":"\f369",
    "l":"\f36a",
    "label":"\f36b",
    "laptop":"\f36c",
    "lasso":"\f36d",
    "laugh-beam":"\f36e",
    "laugh-squint":"\f36f",
    "laugh-wink":"\f370",
    "laugh":"\f371",
    "layer-minus":"\f372",
    "layer-plus":"\f373",
    "layers":"\f374",
    "layout-fluid":"\f375",
    "leaf":"\f376",
    "lemon":"\f377",
    "letter-case":"\f378",
    "lettuce":"\f379",
    "level-down-alt":"\f37a",
    "level-down":"\f37b",
    "level-up-alt":"\f37c",
    "level-up":"\f37d",
    "life-ring":"\f37e",
    "lightbulb-dollar":"\f37f",
    "line-width":"\f380",
    "link-alt":"\f381",
    "link-slash-alt":"\f382",
    "link-slash":"\f383",
    "link":"\f384",
    "lipstick":"\f385",
    "lira-sign":"\f386",
    "list-check":"\f387",
    "list":"\f388",
    "loading":"\f389",
    "location-alt":"\f38a",
    "lock-alt":"\f38b",
    "lock":"\f38c",
    "luchador":"\f38d",
    "luggage-rolling":"\f38e",
    "m":"\f38f",
    "magic-wand":"\f390",
    "mailbox":"\f391",
    "makeup-brush":"\f392",
    "man-head":"\f393",
    "map-marker-cross":"\f394",
    "map-marker-home":"\f395",
    "map-marker-minus":"\f396",
    "map-marker-plus":"\f397",
    "map-marker":"\f398",
    "map":"\f399",
    "marker-time":"\f39a",
    "marker":"\f39b",
    "mars-double":"\f39c",
    "mars":"\f39d",
    "mask-carnival":"\f39e",
    "mask":"\f39f",
    "medicine":"\f3a0",
    "megaphone":"\f3a1",
    "meh-blank":"\f3a2",
    "meh-rolling-eyes":"\f3a3",
    "meh":"\f3a4",
    "melon":"\f3a5",
    "menu-burger":"\f3a6",
    "menu-dots-vertical":"\f3a7",
    "menu-dots":"\f3a8",
    "meteor":"\f3a9",
    "microphone-alt":"\f3aa",
    "microphone":"\f3ab",
    "mind-share":"\f3ac",
    "minus-small":"\f3ad",
    "minus":"\f3ae",
    "mobile-button":"\f3af",
    "mobile-notch":"\f3b0",
    "mobile":"\f3b1",
    "mode-landscape":"\f3b2",
    "mode-portrait":"\f3b3",
    "money-bill-wave-alt":"\f3b4",
    "money-bill-wave":"\f3b5",
    "money-check":"\f3b6",
    "money-check-edit-alt":"\f3b7",
    "money-check-edit":"\f3b8",
    "money":"\f3b9",
    "moon-stars":"\f3ba",
    "moon":"\f3bb",
    "motorcycle":"\f3bc",
    "mountains":"\f3bd",
    "mouse":"\f3be",
    "mug-alt":"\f3bf",
    "mug-hot-alt":"\f3c0",
    "mug-hot":"\f3c1",
    "mug-tea":"\f3c2",
    "mug":"\f3c3",
    "mushroom":"\f3c4",
    "music-file":"\f3c5",
    "music-alt":"\f3c6",
    "music":"\f3c7",
    "n":"\f3c8",
    "navigation":"\f3c9",
    "network-cloud":"\f3ca",
    "network":"\f3cb",
    "noodles":"\f3cc",
    "notebook":"\f3cd",
    "o":"\f3ce",
    "oil-can":"\f3cf",
    "oil-temp":"\f3d0",
    "olive-oil":"\f3d1",
    "olives":"\f3d2",
    "onion":"\f3d3",
    "opacity":"\f3d4",
    "overline":"\f3d5",
    "p":"\f3d6",
    "package":"\f3d7",
    "page-break":"\f3d8",
    "paint-roller":"\f3d9",
    "paint-brush":"\f3da",
    "palette":"\f3db",
    "pan":"\f3dc",
    "paper-plane":"\f3dd",
    "password":"\f3de",
    "pause":"\f3df",
    "paw":"\f3e0",
    "peach":"\f3e1",
    "pencil-ruler":"\f3e2",
    "pencil":"\f3e3",
    "pennant":"\f3e4",
    "people-poll":"\f3e5",
    "pepper-hot":"\f3e6",
    "pepper":"\f3e7",
    "percentage":"\f3e8",
    "pharmacy":"\f3e9",
    "phone-office":"\f3ea",
    "phone-call":"\f3eb",
    "phone-cross":"\f3ec",
    "phone-pause":"\f3ed",
    "phone-slash":"\f3ee",
    "photo-film-music":"\f3ef",
    "photo-video":"\f3f0",
    "physics":"\f3f1",
    "picnic":"\f3f2",
    "picture":"\f3f3",
    "pie":"\f3f4",
    "piece":"\f3f5",
    "piggy-bank":"\f3f6",
    "pineapple":"\f3f7",
    "ping-pong":"\f3f8",
    "pizza-slice":"\f3f9",
    "plane-alt":"\f3fa",
    "plane":"\f3fb",
    "plate":"\f3fc",
    "play-alt":"\f3fd",
    "play-pause":"\f3fe",
    "play":"\f3ff",
    "playing-cards":"\f400",
    "plus-small":"\f401",
    "plus":"\f402",
    "podium-star":"\f403",
    "podium":"\f404",
    "poker-chip":"\f405",
    "poo":"\f406",
    "popcorn":"\f407",
    "portrait":"\f408",
    "pot":"\f409",
    "pound":"\f40a",
    "power":"\f40b",
    "presentation":"\f40c",
    "print":"\f40d",
    "protractor":"\f40e",
    "pulse":"\f40f",
    "pumpkin":"\f410",
    "puzzle":"\f411",
    "pyramid":"\f412",
    "q":"\f413",
    "question-square":"\f414",
    "question":"\f415",
    "quote-right":"\f416",
    "r":"\f417",
    "racquet":"\f418",
    "radish":"\f419",
    "rainbow":"\f41a",
    "raindrops":"\f41b",
    "rec":"\f41c",
    "receipt":"\f41d",
    "record-vinyl":"\f41e",
    "rectabgle-vertical":"\f41f",
    "rectangle-horizontal":"\f420",
    "rectangle-panoramic":"\f421",
    "recycle":"\f422",
    "redo-alt":"\f423",
    "redo":"\f424",
    "reflect":"\f425",
    "refresh":"\f426",
    "registered":"\f427",
    "remove-user":"\f428",
    "reply-all":"\f429",
    "republican":"\f42a",
    "resize":"\f42b",
    "resources":"\f42c",
    "restaurant":"\f42d",
    "rewind":"\f42e",
    "rhombus":"\f42f",
    "rings-wedding":"\f430",
    "road":"\f431",
    "rocket-lunch":"\f432",
    "rocket":"\f433",
    "room-service":"\f434",
    "rotate-left":"\f435",
    "rotate-right":"\f436",
    "ruble-sign":"\f437",
    "rugby":"\f438",
    "ruler-combined":"\f439",
    "ruler-horizontal":"\f43a",
    "ruler-triangle":"\f43b",
    "ruler-vertical":"\f43c",
    "running":"\f43d",
    "rupee-sign":"\f43e",
    "rv":"\f43f",
    "s":"\f440",
    "sack-dollar":"\f441",
    "sack":"\f442",
    "sad-cry":"\f443",
    "sad-tear":"\f444",
    "sad":"\f445",
    "salad":"\f446",
    "salt-pepper":"\f447",
    "sandwich":"\f448",
    "sauce":"\f449",
    "sausage":"\f44a",
    "scale":"\f44b",
    "school-bus":"\f44c",
    "school":"\f44d",
    "scissors":"\f44e",
    "screen":"\f44f",
    "search-dollar":"\f450",
    "search-location":"\f451",
    "search-alt":"\f452",
    "search-heart":"\f453",
    "search":"\f454",
    "security":"\f455",
    "sensor-alert":"\f456",
    "sensor-fire":"\f457",
    "sensor-on":"\f458",
    "sensor-smoke":"\f459",
    "sensor":"\f45a",
    "settings-sliders":"\f45b",
    "settings":"\f45c",
    "share":"\f45d",
    "shekel-sign":"\f45e",
    "shield-check":"\f45f",
    "shield-exclamation":"\f460",
    "shield-interrogation":"\f461",
    "shield-plus":"\f462",
    "shield":"\f463",
    "ship-side":"\f464",
    "ship":"\f465",
    "shop":"\f466",
    "shopping-basket":"\f467",
    "shopping-bag-add":"\f468",
    "shopping-bag":"\f469",
    "shopping-cart-add":"\f46a",
    "shopping-cart-check":"\f46b",
    "shopping-cart":"\f46c",
    "shrimp":"\f46d",
    "shuffle":"\f46e",
    "shuttle-van":"\f46f",
    "shuttlecock":"\f470",
    "sign-in-alt":"\f471",
    "sign-out-alt":"\f472",
    "signal-alt-1":"\f473",
    "signal-alt-2":"\f474",
    "signal-alt":"\f475",
    "skateboard":"\f476",
    "skating":"\f477",
    "skewer":"\f478",
    "ski-jump":"\f479",
    "ski-lift":"\f47a",
    "skiing-nordic":"\f47b",
    "skiing":"\f47c",
    "sledding":"\f47d",
    "sleigh":"\f47e",
    "smartphone":"\f47f",
    "smile-beam":"\f480",
    "smile-wink":"\f481",
    "smile":"\f482",
    "smog":"\f483",
    "smoke":"\f484",
    "snow-blowing":"\f485",
    "snowboarding":"\f486",
    "snowflake":"\f487",
    "snowflakes":"\f488",
    "snowmobile":"\f489",
    "snowplow":"\f48a",
    "soap":"\f48b",
    "social-network":"\f48c",
    "sort-alpha-down-alt":"\f48d",
    "sort-alpha-down":"\f48e",
    "sort-alpha-up-alt":"\f48f",
    "sort-alpha-up":"\f490",
    "sort-alt":"\f491",
    "sort-amount-down-alt":"\f492",
    "sort-amount-down":"\f493",
    "sort-amount-up-alt":"\f494",
    "sort-amount-up":"\f495",
    "sort-down":"\f496",
    "sort-numeric-down-alt":"\f497",
    "sort-numeric-down":"\f498",
    "sort":"\f499",
    "soup":"\f49a",
    "spa":"\f49b",
    "space-shuttle":"\f49c",
    "spade":"\f49d",
    "sparkles":"\f49e",
    "speaker":"\f49f",
    "sphere":"\f4a0",
    "spinner":"\f4a1",
    "spoon":"\f4a2",
    "sport":"\f4a3",
    "square-root":"\f4a4",
    "square":"\f4a5",
    "stamp":"\f4a6",
    "star-octogram":"\f4a7",
    "star":"\f4a8",
    "starfighter":"\f4a9",
    "stars":"\f4aa",
    "stats":"\f4ab",
    "steak":"\f4ac",
    "steering-wheel":"\f4ad",
    "stethoscope":"\f4ae",
    "sticker":"\f4af",
    "stop":"\f4b0",
    "stopwatch":"\f4b1",
    "store-alt":"\f4b2",
    "strawberry":"\f4b3",
    "subtitles":"\f4b4",
    "subway":"\f4b5",
    "suitcase-alt":"\f4b6",
    "summer":"\f4b7",
    "sun":"\f4b8",
    "sunrise-alt":"\f4b9",
    "sunrise":"\f4ba",
    "sunset":"\f4bb",
    "surfing":"\f4bc",
    "surprise":"\f4bd",
    "sushi":"\f4be",
    "swimmer":"\f4bf",
    "sword":"\f4c0",
    "symbol":"\f4c1",
    "syringe":"\f4c2",
    "t":"\f4c3",
    "tablet":"\f4c4",
    "tachometer-alt-average":"\f4c5",
    "tachometer-alt-fastest":"\f4c6",
    "tachometer-alt-slow":"\f4c7",
    "tachometer-alt-slowest":"\f4c8",
    "tachometer-average":"\f4c9",
    "tachometer-fast":"\f4ca",
    "tachometer-fastest":"\f4cb",
    "tachometer-slow":"\f4cc",
    "tachometer-slowest":"\f4cd",
    "tachometer":"\f4ce",
    "taco":"\f4cf",
    "tags":"\f4d0",
    "tally":"\f4d1",
    "target":"\f4d2",
    "taxi":"\f4d3",
    "temperature-down":"\f4d4",
    "temperature-frigid":"\f4d5",
    "temperature-high":"\f4d6",
    "temperature-low":"\f4d7",
    "temperature-up":"\f4d8",
    "tenge":"\f4d9",
    "tennis":"\f4da",
    "terrace":"\f4db",
    "test-tube":"\f4dc",
    "test":"\f4dd",
    "text-check":"\f4de",
    "text-slash":"\f4df",
    "text":"\f4e0",
    "thermometer-half":"\f4e1",
    "thumbtack":"\f4e2",
    "thunderstorm-moon":"\f4e3",
    "thunderstorm-sun":"\f4e4",
    "thunderstorm":"\f4e5",
    "ticket":"\f4e6",
    "time-quarter-past":"\f4e7",
    "time-add":"\f4e8",
    "time-check":"\f4e9",
    "time-delete":"\f4ea",
    "time-fast":"\f4eb",
    "time-forward-sixty":"\f4ec",
    "time-forward-ten":"\f4ed",
    "time-forward":"\f4ee",
    "time-half-past":"\f4ef",
    "time-oclock":"\f4f0",
    "time-past":"\f4f1",
    "time-quarter-to":"\f4f2",
    "time-twenty-four":"\f4f3",
    "tire-flat":"\f4f4",
    "tire-pressure-warning":"\f4f5",
    "tire-rugged":"\f4f6",
    "tire":"\f4f7",
    "tired":"\f4f8",
    "tomato":"\f4f9",
    "tool-box":"\f4fa",
    "tool-crop":"\f4fb",
    "tool-marquee":"\f4fc",
    "tooth":"\f4fd",
    "tornado":"\f4fe",
    "tractor":"\f4ff",
    "trailer":"\f500",
    "train-side":"\f501",
    "train":"\f502",
    "tram":"\f503",
    "transform":"\f504",
    "trash":"\f505",
    "treasure-chest":"\f506",
    "treatment":"\f507",
    "tree-christmas":"\f508",
    "tree":"\f509",
    "triangle":"\f50a",
    "trophy":"\f50b",
    "truck-container":"\f50c",
    "truck-couch":"\f50d",
    "truck-loading":"\f50e",
    "truck-monster":"\f50f",
    "truck-moving":"\f510",
    "truck-pickup":"\f511",
    "truck-plow":"\f512",
    "truck-ramp":"\f513",
    "truck-side":"\f514",
    "tty":"\f515",
    "turkey":"\f516",
    "tv-music":"\f517",
    "typewriter":"\f518",
    "u":"\f519",
    "umbrella":"\f51a",
    "underline":"\f51b",
    "undo-alt":"\f51c",
    "undo":"\f51d",
    "unlock":"\f51e",
    "upload":"\f51f",
    "usb-pendrive":"\f520",
    "usd-circle":"\f521",
    "usd-square":"\f522",
    "user-add":"\f523",
    "user-time":"\f524",
    "user":"\f525",
    "users-alt":"\f526",
    "users":"\f527",
    "utensils":"\f528",
    "v":"\f529",
    "vector-alt":"\f52a",
    "vector":"\f52b",
    "venus-double":"\f52c",
    "venus-mars":"\f52d",
    "venus":"\f52e",
    "video-arrow-down-left":"\f52f",
    "video-arrow-up-right":"\f530",
    "video-camera-alt":"\f531",
    "video-camera":"\f532",
    "video-plus":"\f533",
    "video-slash":"\f534",
    "volcano":"\f535",
    "volleyball":"\f536",
    "volume":"\f537",
    "w":"\f538",
    "wagon-covered":"\f539",
    "wallet":"\f53a",
    "water-bottle":"\f53b",
    "water-lower":"\f53c",
    "water-rise":"\f53d",
    "water":"\f53e",
    "watermelon":"\f53f",
    "wheat":"\f540",
    "wheelchair":"\f541",
    "whistle":"\f542",
    "wifi-alt":"\f543",
    "wind-warning":"\f544",
    "wind":"\f545",
    "windsock":"\f546",
    "woman-head":"\f547",
    "world":"\f548",
    "x":"\f549",
    "y":"\f54a",
    "yen":"\f54b",
    "z":"\f54c",
    "zoom-in":"\f54d",
    "zoom-out":"\f54e",


    >>> BRANDS ICONS ($uicons-brands-fonts)
    "3m":"\f101",
    "500px":"\f102",
    "abbot-laboratories":"\f103",
    "accusoft":"\f104",
    "acrobat":"\f105",
    "adobe":"\f106",
    "aecom":"\f107",
    "aero":"\f108",
    "after-effects":"\f109",
    "airbnb":"\f10a",
    "algolia":"\f10b",
    "amd":"\f10c",
    "american-express":"\f10d",
    "android":"\f10e",
    "animate":"\f10f",
    "app-store-ios":"\f110",
    "apple-pay":"\f111",
    "apple":"\f112",
    "artstation":"\f113",
    "astrazeneca":"\f114",
    "asus":"\f115",
    "atandt":"\f116",
    "atlassian":"\f117",
    "atom":"\f118",
    "audition":"\f119",
    "behance":"\f11a",
    "bitcoin":"\f11b",
    "blackberry":"\f11c",
    "blogger":"\f11d",
    "bluetooth":"\f11e",
    "bootstrap":"\f11f",
    "bridgestone":"\f120",
    "burger-king":"\f121",
    "c":"\f122",
    "capture":"\f123",
    "cc-apple-pay":"\f124",
    "cc-diners-club":"\f125",
    "cc-visa":"\f126",
    "cc-amazon-pay":"\f127",
    "centos":"\f128",
    "character":"\f129",
    "chromecast":"\f12a",
    "cloudflare":"\f12b",
    "confluence":"\f12c",
    "creative-commons-by":"\f12d",
    "creative-commons-nc-eu":"\f12e",
    "creative-commons-nc-jp":"\f12f",
    "creative-commons-nc":"\f130",
    "creative-commons-nd":"\f131",
    "creative-commons-pd-alt":"\f132",
    "creative-commons-pd":"\f133",
    "creative-commons-remix":"\f134",
    "creative-commons-sa":"\f135",
    "creative-commons-sampling-plus":"\f136",
    "creative-commons-sampling":"\f137",
    "creative-commons-share":"\f138",
    "creative-commons-zero":"\f139",
    "creative-commons":"\f13a",
    "css3-alt":"\f13b",
    "css3":"\f13c",
    "dailymotion":"\f13d",
    "deezer":"\f13e",
    "delphi":"\f13f",
    "dev":"\f140",
    "devianart":"\f141",
    "digg":"\f142",
    "dimension":"\f143",
    "discord":"\f144",
    "docker":"\f145",
    "dribbble":"\f146",
    "dropbox":"\f147",
    "drupal":"\f148",
    "ebay":"\f149",
    "elementor":"\f14a",
    "ethereum":"\f14b",
    "etsy":"\f14c",
    "evernote":"\f14d",
    "facebook-messenger":"\f14e",
    "facebook":"\f14f",
    "fedex":"\f150",
    "figma":"\f151",
    "firefox-browser":"\f152",
    "firefox":"\f153",
    "flickr":"\f154",
    "flipboard":"\f155",
    "fonts":"\f156",
    "foursquare":"\f157",
    "fresco":"\f158",
    "github":"\f159",
    "gitlab":"\f15a",
    "goodreads":"\f15b",
    "google":"\f15c",
    "haskell":"\f15d",
    "hbo":"\f15e",
    "hotjar":"\f15f",
    "html5":"\f160",
    "huawei":"\f161",
    "hubspot":"\f162",
    "ibm":"\f163",
    "illustrator-draw":"\f164",
    "illustrator":"\f165",
    "imdb":"\f166",
    "incopy":"\f167",
    "indesign":"\f168",
    "instagram":"\f169",
    "intel":"\f16a",
    "invision":"\f16b",
    "itunes":"\f16c",
    "janseen":"\f16d",
    "java":"\f16e",
    "jcb":"\f16f",
    "jira":"\f170",
    "johnson-and-johnson":"\f171",
    "joomla":"\f172",
    "js":"\f173",
    "kickstarter":"\f174",
    "line":"\f175",
    "linkedin":"\f176",
    "lisp":"\f177",
    "mailchimp":"\f178",
    "marriott-international":"\f179",
    "mcdonalds":"\f17a",
    "media-encoder":"\f17b",
    "medium":"\f17c",
    "meta":"\f17d",
    "microsoft-edge":"\f17e",
    "microsoft-explorer":"\f17f",
    "microsoft":"\f180",
    "mysql":"\f181",
    "napster":"\f182",
    "nestle":"\f183",
    "netflix":"\f184",
    "node-js":"\f185",
    "nvidia":"\f186",
    "oracle":"\f187",
    "patreon":"\f188",
    "paypal":"\f189",
    "pfizer":"\f18a",
    "photoshop-camera":"\f18b",
    "photoshop-express":"\f18c",
    "photoshop-lightroom-classic":"\f18d",
    "photoshop-lightroom":"\f18e",
    "photoshop":"\f18f",
    "php":"\f190",
    "pinterest":"\f191",
    "postgre":"\f192",
    "premiere-rush":"\f193",
    "premiere":"\f194",
    "product-hunt":"\f195",
    "python":"\f196",
    "raspberry-pi":"\f197",
    "reddit":"\f198",
    "samsung":"\f199",
    "sap":"\f19a",
    "sass":"\f19b",
    "shopify":"\f19c",
    "siemens":"\f19d",
    "sketch":"\f19e",
    "skype":"\f19f",
    "slack":"\f1a0",
    "snapchat":"\f1a1",
    "sony":"\f1a2",
    "soundcloud":"\f1a3",
    "spark":"\f1a4",
    "spotify":"\f1a5",
    "starbucks":"\f1a6",
    "stock":"\f1a7",
    "stripe":"\f1a8",
    "substance-3d-designer":"\f1a9",
    "substance-3d-painter":"\f1aa",
    "substance-3d-sampler":"\f1ab",
    "substance-3d-stager":"\f1ac",
    "swift":"\f1ad",
    "t-mobile":"\f1ae",
    "telegram":"\f1af",
    "tencent":"\f1b0",
    "the-home-depot":"\f1b1",
    "tik-tok":"\f1b2",
    "trello":"\f1b3",
    "tripadvisor":"\f1b4",
    "tumblr":"\f1b5",
    "twitch":"\f1b6",
    "twitter":"\f1b7",
    "typescript":"\f1b8",
    "uber":"\f1b9",
    "ubuntu":"\f1ba",
    "unilever":"\f1bb",
    "unity":"\f1bc",
    "unsplash":"\f1bd",
    "ups":"\f1be",
    "usaa":"\f1bf",
    "verizon":"\f1c0",
    "vimeo":"\f1c1",
    "visa":"\f1c2",
    "visual-basic":"\f1c3",
    "vk":"\f1c4",
    "walmart":"\f1c5",
    "whatsapp":"\f1c6",
    "wikipedia":"\f1c7",
    "windows":"\f1c8",
    "wix":"\f1c9",
    "wordpress":"\f1ca",
    "xd":"\f1cb",
    "xing":"\f1cc",
    "yahoo":"\f1cd",
    "yandex":"\f1ce",
    "yelp":"\f1cf",
    "youtube":"\f1d0",
    "zoom":"\f1d1",
*/
/* >>> GENERAL UICONS 
------------------------------ */
@font-face {
  font-family: "uicons-regular-rounded";
  src: url("/themes/biokide/fonts/uicons-regular-rounded.woff2") format("woff2"), url("/themes/biokide/fonts/uicons-regular-rounded.woff") format("woff");
  font-display: swap;
}
.fig-arrow-left::before {
  content: "\f14a";
}

.fig-arrow-right::before {
  content: "\f14b";
}

.scroll-top::before, .fig-arrow-to-top::before {
  content: "\f157";
}

.carousel-navigations-2 .prev::before, .carousel-navigations .prev::before, .fig-angle-left::before {
  content: "\f11b";
}

.carousel-navigations-2 .next::before, .carousel-navigations .next::before, .fig-angle-right::before {
  content: "\f11c";
}

.checkbox-group .label::before, .fig-check::before {
  content: "\f1fb";
}

.side-panel__close::before, .menu-overlay__close::before, .alert-container .alert__close::before, .fig-cross::before {
  content: "\f27c";
}

.see-password-wrap .see-password.active::before, .fig-eye::before {
  content: "\f2ce";
}

.see-password-wrap .see-password::before, .fig-eye-crossed::before {
  content: "\f2cc";
}

.fig-heart::before {
  content: "\f33d";
}

.fig-home::before {
  content: "\f346";
}

.fig-globe::before {
  content: "\f315";
}

.fig-leaf::before {
  content: "\f376";
}

.button-loading::before, .fig-loading::before {
  content: "\f389";
}

.fig-map-marker::before {
  content: "\f398";
}

.qty__remove::before, .fig-minus-small::before {
  content: "\f3ad";
}

.fig-phone-call::before {
  content: "\f3eb";
}

.qty__add::before, .fig-plus-small::before {
  content: "\f401";
}

.fig-quote-right::before {
  content: "\f416";
}

.fig-shopping-cart::before {
  content: "\f46c";
}

.fig-shopping-cart-add::before {
  content: "\f46a";
}

.fig-tags::before {
  content: "\f4d0";
}

.fig-trash::before {
  content: "\f505";
}

.fig-user::before {
  content: "\f525";
}

.fig-user-add::before {
  content: "\f523";
}

/* >>> BRANDS UICONS 
------------------------------ */
@font-face {
  font-family: "uicons-brands";
  src: url("/themes/biokide/fonts/uicons-brands.woff2") format("woff2"), url("/themes/biokide/fonts/uicons-brands.woff") format("woff");
  font-display: swap;
}
.fib-facebook::before {
  content: "\f14f";
}

.fib-instagram::before {
  content: "\f169";
}

.fib-twitter::before {
  content: "\f1b7";
}

@keyframes fadeBottomSmall {
  from {
    opacity: 0;
    transform: translateY(-1rem);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
@-webkit-keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 5rem, 0);
    transform: translate3d(0, 5rem, 0);
    visibility: visible;
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    visibility: visible;
  }
}
@keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 5rem, 0);
    transform: translate3d(0, 5rem, 0);
    visibility: visible;
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    visibility: visible;
  }
}
.fadeInUp, .animated.fadeInUp {
  animation: fadeInUp 1s forwards;
}

@-webkit-keyframes fadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(5rem, 0, 0);
    transform: translate3d(5rem, 0, 0);
    visibility: visible;
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    visibility: visible;
  }
}
@keyframes fadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(5rem, 0, 0);
    transform: translate3d(5rem, 0, 0);
    visibility: visible;
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    visibility: visible;
  }
}
.fadeInRight, .animated.fadeInRight {
  animation: fadeInRight 1s forwards;
}

@-webkit-keyframes fadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-5rem, 0, 0);
    transform: translate3d(-5rem, 0, 0);
    visibility: visible;
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    visibility: visible;
  }
}
@keyframes fadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-5rem, 0, 0);
    transform: translate3d(-5rem, 0, 0);
    visibility: visible;
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    visibility: visible;
  }
}
.fadeInLeft, .animated.fadeInLeft {
  animation: fadeInLeft 1s forwards;
}

@keyframes growTransp {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    height: 2rem;
    width: 2rem;
  }
}
@keyframes spinning {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes spinningCenter {
  from {
    transform: translate(-50%, -50%) rotate(0);
  }
  to {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}
*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  font-size: 62.5%;
}
@media (max-width: 74.99em) {
  html {
    font-size: 50%;
  }
}

body {
  color: var(--color-grey);
  font-family: var(--font-family-1st);
  font-size: 2rem;
  font-weight: 300;
  line-height: 1.4;
}
body.preload * {
  transition: none !important;
}

a {
  color: currentColor;
  text-decoration: none;
  transition: 0.4s;
}
a:hover, a:focus {
  color: var(--color-primary);
}

img {
  display: inline-block;
  max-width: 100%;
}

input, textarea {
  font-family: var(--font-family-1st);
}

ul {
  list-style: none;
}

#wrapper {
  overflow-x: hidden;
  overflow-y: scroll;
  position: relative;
  height: 100vh;
  width: 100%;
}

::selection {
  background-color: var(--color-primary);
  color: #fff;
}

::-webkit-scrollbar {
  position: relative;
  width: 8px;
  z-index: 9999999999;
}

::-webkit-scrollbar-track {
  background: #000;
}

::-webkit-scrollbar-thumb {
  background: var(--color-primary);
  border-radius: 10px;
}

.checkbox-group {
  cursor: pointer;
  display: inline-block;
}
.checkbox-group input {
  display: none;
}
.checkbox-group input:checked ~ .label {
  color: var(--color-primary);
}
.checkbox-group input:checked ~ .label::before {
  background-color: var(--color-primary);
  border-color: var(--color-primary);
}
.checkbox-group .label {
  display: inline-flex;
  align-items: flex-start;
  position: relative;
  transition: 0.4s;
}
.checkbox-group .label::before {
  background-color: #fff;
  border: 2px solid rgba(178, 168, 147, 0.3);
  color: #fff;
  display: inline-block;
  margin-right: 1rem;
  padding: 2px;
  transition: 0.4s;
}
.checkbox-group--radio .label::before {
  border-radius: 99rem;
}

.qty {
  height: 6.5rem;
  padding: 0.5rem 1rem;
}
.qty__remove::before {
  transform: none;
}
.qty__add::before {
  transform: none;
}
.qty__remove, .qty__add {
  background-color: var(--color-secondary);
  color: #fff;
  height: 4.5rem;
  width: 4.5rem;
}
.qty__input {
  background: transparent;
  border: none;
  color: var(--color-tertiary);
  font-family: var(--font-family-1st);
  font-size: 3.5rem;
  font-weight: bold;
  text-align: center;
  width: 7rem;
}
.qty--sm {
  height: auto;
}
.qty--sm .qty__remove, .qty--sm .qty__add {
  height: 3rem;
  width: 3rem;
}
.qty--sm .qty__input {
  font-size: 2.5rem;
  width: 5rem;
}

.fieldset-1 {
  border: none;
  margin-bottom: 3rem;
}
.form-group {
  margin-bottom: 2rem;
}

.form-group-sm {
  margin-bottom: 1rem;
}

.form-group-button {
  display: flex;
  flex-flow: row wrap;
}
.form-group-button input {
  background-color: #fff;
  border: 2px solid var(--color-tertiary-2);
  flex-grow: 1;
  font-size: 2rem;
  font-weight: bold;
  padding: 0.5rem 2rem;
  height: 5rem;
  transition: 0.4s;
}
.form-group-button input:hover, .form-group-button input:focus-visible {
  border-color: var(--color-primary);
  outline: none !important;
}
.input-1, .select-nice {
  background-color: #fff;
  border: 2px solid rgba(178, 168, 147, 0.3);
  color: var(--color-tertiary);
  display: block;
  font-size: 1.8rem;
  font-weight: bold;
  padding: 0.5em 1em;
  width: 100%;
  transition: 0.4s;
}
.input-1:hover, .select-nice:hover, .input-1:focus-visible, .select-nice:focus-visible {
  border-color: var(--color-primary);
  outline: none;
}

.notif {
  display: inline-block;
  font-size: 1.8rem;
  font-weight: bold;
  margin-top: 0.4rem;
  padding-left: 2.5rem;
  position: relative;
}
.notif::before, .notif::after {
  content: "";
  border-radius: 99rem;
  position: absolute;
  left: 0.8rem;
  top: 0.8em;
  height: 0.6rem;
  width: 0.6rem;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.notif::after {
  -webkit-animation: growTransp 1s infinite backwards;
  animation: growTransp 1s infinite backwards;
}
.notif--success {
  color: var(--color-success);
}
.notif--success::before, .notif--success::after {
  background-color: var(--color-success);
}
.notif--danger {
  color: var(--color-danger);
}
.notif--danger::before, .notif--danger::after {
  background-color: var(--color-danger);
}

.see-password-wrap {
  position: relative;
}
.see-password-wrap .see-password {
  cursor: pointer;
  line-height: 1;
  position: absolute;
  top: 50%;
  right: 2rem;
  transform: translateY(-50%);
}
.see-password-wrap .see-password.active::before {
  color: var(--color-primary);
}

.select-nice {
  line-height: 1.2;
  padding-right: 4rem;
  position: relative;
}
.select-nice::before {
  content: "►";
  color: var(--color-primary);
  display: inline-block;
  font-size: 0.8em;
  position: absolute;
  right: 1.5rem;
  top: 50%;
  transform: translateY(-50%) rotate(90deg);
}

.notif {
  color: var(--color-danger);
  display: block;
  font-size: 14px;
  font-weight: normal;
  line-height: 22px;
  padding-left: 15px;
  position: relative;
}
.notif::before {
  content: "";
  border-radius: 10px;
  background-color: var(--color-danger);
  line-height: 0;
  position: absolute;
  left: 0.3rem;
  top: 1.1rem;
  height: 6px;
  width: 6px;
}
.notif::after {
  content: "";
  background-color: var(--color-danger);
  border-radius: 4px;
  position: absolute;
  top: 0.9rem;
  left: 0.1rem;
  height: 4px;
  width: 4px;
  animation: grow-transp 1s ease-in-out infinite forwards;
}

.notif-form-success {
  display: flex;
  align-items: center;
  justify-content: center;
}
.notif-form-success svg {
  flex-shrink: 0;
  margin-right: 15px;
  height: 50px;
  width: 50px;
}
.notif-form-success svg > * {
  stroke-dasharray: 1200;
  stroke-dashoffset: 1200;
  stroke: var(--color-success);
}
.notif-form-success svg > *:first-child {
  animation: stroke-appear-1 0.75s 0.5s cubic-bezier(0.33, 1, 0.68, 1) forwards;
}
.notif-form-success svg > *:last-child {
  animation: stroke-appear-1 1s 1s cubic-bezier(0.5, 1, 0.89, 1) forwards;
}
.notif-form-success span {
  color: var(--color-success);
  font-size: 18px;
  animation: fade-in 0.5s ease-in-out forwards;
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes grow-transp {
  from {
    opacity: 0.8;
    transform: scale(1);
  }
  to {
    opacity: 0;
    transform: scale(4);
  }
}
@keyframes stroke-appear-1 {
  from {
    stroke-dasharray: 1200;
    stroke-dashoffset: 1200;
  }
  to {
    stroke-dasharray: 1200;
    stroke-dashoffset: 0;
  }
}
.coupon-code {
  position: relative;
}
.coupon-code__input {
  position: absolute;
  transition: 0.4s;
}
.coupon-code.active .coupon-code__button {
  display: none;
}
.coupon-code.active .coupon-code__input {
  position: relative;
}

.list-group .list-group-item:last-child {
  border: none !important;
}

.separator-1 {
  margin: 2rem 0;
  position: relative;
  height: 9.3rem;
  width: 100%;
}
.separator-1::before {
  content: "";
  background-image: url("/storage/svg/african-line-overlay-1-secondary-white.svg");
  background-size: contain;
  position: absolute;
  left: 50%;
  top: 0;
  transform: translateX(-50%);
  height: 100%;
  width: 200vw;
}

.link {
  display: inline-flex;
  align-items: center;
  gap: 1.5rem;
}
.link--gapSm {
  gap: 0.5rem;
}
.link--white {
  color: #fff;
}
.link--white:hover, .link--white:focus-visible {
  color: #fff;
}
.link--white:hover::after, .link--white:focus-visible::after {
  background-color: #fff;
}
.link--tertiary {
  color: var(--color-tertiary);
}
.link--tertiary:hover, .link--tertiary:focus-visible {
  color: var(--color-tertiary);
}
.link--tertiary:hover::after, .link--tertiary:focus-visible::after {
  background-color: var(--color-tertiary);
}
.link--sm {
  font-size: 1.8rem;
}
.link--lg {
  font-size: 2.5rem;
}

.paras > p:not(:last-child) {
  margin-bottom: 0.8em;
}
.paras--lg {
  font-size: 2.4rem;
}
.paras--xl {
  font-size: 2.8rem;
}

.quote-text {
  color: var(--color-primary);
}
.quote-text q {
  font-style: italic;
  font-weight: bold;
}

.section-title {
  margin-bottom: 5rem;
}
.section-title__main {
  margin-bottom: 2rem;
}
.section-title--1 {
  position: relative;
  text-align: center;
}
.section-title--1 .section-title__sup {
  color: #fff;
}
.section-title--1 .section-title__main {
  background-color: #fff;
  display: inline-block;
  padding: 0.5rem 4rem;
}
.section-title--1 .section-title__icon {
  background-color: var(--color-secondary);
  border: 1.2rem solid var(--color-primary-3);
  border-radius: 99rem;
  color: #fff;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 58%);
  height: 8.4rem;
  width: 8.4rem;
}

.text-note {
  font-size: 2.8rem;
  font-style: italic;
}

.text-written {
  font-family: var(--font-family-3rd);
  line-height: 1.3;
  text-align: left;
  transform: rotate(-5deg);
}
.text-written span {
  display: inline-block;
}
.text-written span:last-child {
  margin-left: 8rem;
}
.text-written--1 {
  font-size: 3.4rem;
}
.text-written--1 span:last-child {
  font-size: 1.5em;
}
.text-written--2 {
  font-size: 5rem;
}

.menu-toggle {
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: block;
  flex-flow: column nowrap;
  justify-content: space-between;
  position: relative;
  height: 2.5rem;
  width: 4rem;
}
.menu-toggle::before, .menu-toggle::after, .menu-toggle > span {
  background-color: #fff;
  border-radius: 99rem;
  display: block;
  position: absolute;
  left: 0;
  height: 3px;
  transition: 0.4s;
}
.menu-toggle::before {
  content: "";
  top: 0;
  width: 100%;
}
.menu-toggle > span {
  top: calc(50% - 2px);
  width: 75%;
}
.menu-toggle::after {
  content: "";
  bottom: 0;
  width: 50%;
}
.menu-toggle:hover::before, .menu-toggle:hover::after, .menu-toggle:hover > span {
  background-color: var(--color-primary);
  width: 100%;
}

.shooting-star {
  position: relative;
}
.shooting-star::after {
  content: "";
  background: url("/storage/svg/shooting-star.svg") center center no-repeat;
  background-size: cover;
  width: 24rem;
  height: 8rem;
  z-index: 0;
  position: absolute;
  top: 100%;
  right: 0;
}

.alert-container {
  position: fixed;
  top: 20%;
  right: 5rem;
  z-index: 9999999999;
  max-width: 60rem;
  width: 100%;
}
.alert-container .alert {
  gap: 2rem;
  margin-bottom: 1.5rem;
  padding: 2.5rem 4.2rem 4.5rem 2.5rem;
  position: relative;
  width: 100%;
}
.alert-container .alert::after {
  content: "";
  background: url("/storage/svg/african-line-overlay-1-white.svg") center center no-repeat;
  background-size: cover;
  width: 100%;
  height: 3rem;
  z-index: 0;
  position: absolute;
  left: 50%;
  bottom: 0.5rem;
  transform: translateX(-50%);
  opacity: 0.2;
}
.alert-container .alert--new {
  animation: fadeInUp 0.4s ease-in-out forwards;
}
.alert-container .alert--danger {
  background-color: var(--color-danger);
  color: #fff;
}
.alert-container .alert--success {
  background-color: var(--color-primary);
  color: #fff;
}
.alert-container .alert__close {
  cursor: pointer;
  font-size: 2rem;
  position: absolute;
  top: 1rem;
  right: 1rem;
}
.alert-container .alert__icon {
  font-size: 4rem;
}
.alert-container .alert__message {
  font-size: 2rem;
}

.bottom-navigation {
  background-color: var(--color-tertiary);
  display: none;
  align-items: center;
  justify-content: space-around;
  gap: 1.5rem;
  padding: 1.5rem;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 10;
}

.button-primary {
  background-color: var(--color-primary);
  border-color: var(--color-primary);
  color: #fff;
}
.button-primary:hover, .button-primary:focus-visible {
  background-color: transparent;
  color: var(--color-primary);
}

.button-primary-outline {
  background-color: transparent;
  border-color: var(--color-primary);
  color: var(--color-primary);
}
.button-primary-outline:hover, .button-primary-outline:focus-visible {
  background-color: var(--color-primary);
  color: #fff;
}

.button-secondary-2 {
  background-color: var(--color-secondary-2);
  border-color: var(--color-secondary-2);
  color: var(--color-secondary);
}
.button-secondary-2:hover, .button-secondary-2:focus-visible {
  background-color: var(--color-secondary);
  border-color: var(--color-secondary);
  color: #fff;
}

.button-secondary-outline {
  background-color: transparent;
  border-color: var(--color-secondary);
  color: var(--color-secondary);
}
.button-secondary-outline:hover, .button-secondary-outline:focus-visible {
  background-color: var(--color-secondary);
  border-color: var(--color-secondary);
  color: #fff;
}

.button-tertiary, .form-group-button button {
  background-color: var(--color-tertiary);
  border-color: var(--color-tertiary);
  color: #fff;
}
.button-tertiary:hover, .form-group-button button:hover, .button-tertiary:focus-visible, .form-group-button button:focus-visible {
  background-color: transparent;
  color: var(--color-tertiary);
}

.button-tertiary-outline {
  background-color: transparent;
  border-color: var(--color-tertiary);
  color: var(--color-tertiary);
}
.button-tertiary-outline:hover, .button-tertiary-outline:focus-visible {
  background-color: var(--color-tertiary);
  color: #fff;
}

.button-white-outline {
  background-color: transparent;
  border-color: #fff;
  color: #fff;
}
.button-white-outline:hover, .button-white-outline:focus-visible {
  background-color: #fff;
  color: var(--color-dark);
}

.button--xs {
  font-size: 2rem;
  padding: 0.5rem 1.5rem;
}

.button--sm, .form-group-button button {
  font-size: 2.2rem;
  padding: 1rem 2.5rem;
}

.button--lg {
  font-size: 2.5rem;
  padding: 2rem 4rem;
}

.button-icon-primary {
  border-color: var(--color-primary);
  color: var(--color-primary);
}
.button-icon-primary:hover, .button-icon-primary:focus-visible {
  background-color: var(--color-primary);
  border-color: var(--color-primary);
  color: #fff;
}

.button-icon-secondary-h {
  background-color: transparent;
  border-color: transparent;
  color: var(--color-secondary);
}
.button-icon-secondary-h:hover, .button-icon-secondary-h:focus-visible {
  background-color: var(--color-secondary);
  border-color: var(--color-secondary);
  color: #fff;
}

.button-icon--sm {
  border-radius: 4.5rem;
  font-size: 2.5rem;
  height: 4.5rem;
  width: 4.5rem;
}

.button-action {
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-size: 2.8rem;
  gap: 1rem;
  position: relative;
}
.button-action i {
  color: #fff;
  transition: 0.4s;
}
.button-action__pellet {
  background-color: var(--color-primary);
  border-radius: 3rem;
  color: #fff;
  font-size: 1.6rem;
  font-weight: bold;
  position: absolute;
  top: -1.2rem;
  right: -1.2rem;
  height: 2.5rem;
  width: 2.5rem;
}
.button-action__text {
  color: #fff;
  font-size: 1.8rem;
  font-weight: 500;
  transition: 0.4s;
}
.button-action:hover i, .button-action:hover .button-action__text, .button-action:focus-within i, .button-action:focus-within .button-action__text {
  color: var(--color-primary);
}

.button-loading {
  position: relative;
}
.button-loading::before {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  animation: spinningCenter 3s linear infinite;
}
.btn-wrap {
  flex-flow: row wrap;
  gap: 1.5rem 3rem;
}
.btn-wrap--between {
  justify-content: space-between;
}
.btn-wrap--center {
  justify-content: center;
}
.btn-wrap--end {
  justify-content: flex-end;
}

.card {
  background-color: #fff;
  padding: 5rem 6rem;
}
.card--1, .reference__content, .card-image__content {
  padding-bottom: 12rem;
  overflow: hidden;
  z-index: 2;
}
.card--1::before, .reference__content::before, .card-image__content::before {
  content: "";
  background: url("/storage/svg/baobab-fruit-secondary-white.svg") center center no-repeat;
  background-size: cover;
  width: 36.5rem;
  height: 36.5rem;
  z-index: 0;
  position: absolute;
  top: -12rem;
  right: -4rem;
  z-index: -1;
}
.card--1::after, .reference__content::after, .card-image__content::after {
  content: "";
  background: url("/storage/svg/african-line-overlay-1-secondary-white.svg") center center repeat-x;
  background-size: auto 100%;
  width: 100%;
  height: 9.3rem;
  z-index: 0;
  position: absolute;
  left: 0;
  bottom: 0.5rem;
  z-index: -1;
}

.card-sm {
  padding: 2rem;
}

.card-image {
  position: relative;
}
.card-image__image {
  border-radius: 999rem;
  overflow: hidden;
  position: absolute;
  left: 18rem;
  top: -5rem;
  transform: translateX(-50%);
  height: 90%;
  width: 28rem;
  z-index: 3;
}
.card-image__content {
  background-color: var(--color-secondary-2);
  padding: 5rem 5rem 10rem 20rem;
  position: relative;
  margin-left: 18rem;
}
.card-image__content::before {
  background-image: url("/storage/svg/baobab-fruit-white.svg");
}
.card-image__content::after {
  background-image: url("/storage/svg/african-line-overlay-1-white.svg");
}

.card-simple {
  background-color: var(--color-secondary-2);
  padding: 15rem 3rem;
  position: relative;
}
.card-simple::before {
  content: "";
  background: url("/storage/svg/african-line-overlay-1-secondary-tt-1.svg") center center repeat-x;
  background-size: auto 100%;
  width: 100%;
  height: 8rem;
  z-index: 0;
  opacity: 0.2;
  position: absolute;
  left: 0;
  top: 0.5rem;
}
.card-simple::after {
  content: "";
  background: url("/storage/svg/african-line-overlay-1-secondary-tt-1.svg") center center repeat-x;
  background-size: auto 100%;
  width: 100%;
  height: 8rem;
  z-index: 0;
  opacity: 0.2;
  position: absolute;
  left: 0;
  bottom: 0.5rem;
}
.card-simple__textFeat {
  margin-bottom: 2rem;
  text-align: center;
}

.carousel-navigations {
  gap: 3rem;
  margin-bottom: 3rem;
}
.carousel-navigations .prev, .carousel-navigations .next {
  background-color: var(--color-secondary);
  border-color: var(--color-secondary);
  color: #fff;
}
.carousel-navigations .prev:hover, .carousel-navigations .prev:focus-visible, .carousel-navigations .next:hover, .carousel-navigations .next:focus-visible {
  background-color: transparent;
  color: var(--color-secondary);
}
.carousel-navigations .prev::before {
  transform: translateX(-1px);
}
.carousel-navigations .next::before {
  transform: translateX(1px);
}

.carousel-navigations-2 {
  gap: 3rem;
  margin-top: 1.5rem;
}
.carousel-navigations-2 .prev, .carousel-navigations-2 .next {
  height: 4rem;
  width: 4rem;
}
.carousel-navigations-2 .prev::before, .carousel-navigations-2 .next::before {
  color: var(--color-secondary);
  cursor: pointer;
  transition: 0.4s;
  transform: none;
}
.carousel-navigations-2 .prev::before:hover, .carousel-navigations-2 .next::before:hover {
  color: var(--color-primary);
}
.carousel-navigations-2 .pagination {
  gap: 1rem;
  flex-shrink: 0;
}
.carousel-navigations-2 .pagination__bullet {
  border: 2px solid var(--color-primary);
  border-radius: 9rem;
  cursor: pointer;
  height: 2.5rem;
  width: 2.5rem;
  transition: 0.4s;
}
.carousel-navigations-2 .pagination__bullet--active {
  background-color: var(--color-primary);
}

.collapse--1 .collapse__item {
  background-color: #fff;
  border: 2px solid var(--color-tertiary-3);
  border-radius: 99rem;
  color: var(--color-primary);
  cursor: pointer;
  display: inline-block;
  font-family: var(--font-family-2nd);
  font-size: 5rem;
  padding: 1rem 5rem;
  transition: 0.4s;
}
.collapse--1 .collapse__item.active {
  background-color: var(--color-primary);
  color: #fff;
}

.contact-block {
  text-align: center;
}
.contact-block__icon {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background-color: var(--color-secondary);
  border-radius: 99rem;
  color: #fff;
  font-size: 3rem;
  margin-bottom: 1.5rem;
  height: 11rem;
  width: 8rem;
}
.contact-block__title {
  color: var(--color-tertiary);
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
}
.contact-block__value {
  font-size: 2.3rem;
}

.language-switcher {
  position: relative;
}
.language-switcher__current {
  background-color: #000;
  border-radius: 6rem;
  color: #fff;
  cursor: pointer;
  font-weight: bold;
  position: relative;
  text-transform: uppercase;
  height: 6rem;
  width: 6rem;
  transition: 0.4s;
  z-index: 1;
}
.language-switcher__current img {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 50%);
  width: 2rem !important;
}
.language-switcher__dropdown {
  background-color: #000;
  position: absolute;
  top: 100%;
  right: -1rem;
  width: 20rem;
  transition: 0.4s;
}
.language-switcher__dropdown li > a {
  color: #fff;
  display: block;
  padding: 2rem;
  transition: 0.4s;
}
.language-switcher__dropdown li > a:hover {
  background-color: #222;
}
.language-switcher:hover .language-switcher__current {
  background-color: var(--color-primary);
}
.language-switcher:hover .language-switcher__dropdown {
  transform: translateY(1rem);
}
.language-switcher--hover2 .language-switcher__dropdown {
  top: auto;
  bottom: 100%;
  left: 50%;
  transform: translateX(-50%);
}
.language-switcher--hover2:hover .language-switcher__dropdown {
  transform: translate(-50%, -1rem);
}

.pellet, .pellet-primary, .product-card__tag {
  border-radius: 99rem;
  display: inline-block;
  font-size: 1.8rem;
  font-weight: bold;
  line-height: 1;
  padding: 0.5rem 1.5rem;
  text-transform: uppercase;
}

.pellet-primary, .product-card__tag {
  background-color: var(--color-primary);
  color: #fff;
}

.pellet--lg {
  font-size: 2.2rem;
  padding: 1rem 2.5rem;
}

.product-card {
  gap: 3rem;
  position: relative;
}
.product-card__image {
  flex-shrink: 0;
  padding-left: 5%;
  text-align: center;
  position: relative;
  height: 40rem;
  width: 25rem;
}
.product-card__image::before {
  content: "";
  background-color: var(--color-secondary-2);
  border-radius: 99rem;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 12.8rem;
  z-index: -1;
}
.product-card__image img {
  max-width: 22.2rem;
  max-height: 32.4rem;
}
.product-card__tags {
  gap: 0.5rem;
  margin-bottom: 0.5rem;
}
.product-card__content {
  flex-grow: 1;
}
.product-card__title {
  color: var(--color-secondary);
  display: block;
  font-size: 3rem;
  text-transform: uppercase;
  min-height: 7.2rem;
}
.product-card__price {
  color: var(--color-primary);
  font-size: 6rem;
  font-weight: bold;
  line-height: 1;
  margin-bottom: 1.5rem;
}
.product-card__desc {
  font-size: 2.2rem;
  margin-bottom: 1rem;
}
.product-card__buttons, .product-card__actions {
  flex-flow: row wrap;
  gap: 2.2rem;
}
.product-card--2 {
  gap: 1rem;
  flex-flow: column wrap;
  margin: 0 auto;
  max-width: 48rem;
}
.product-card--2 .product-card__image {
  padding-left: 0;
  height: 35rem;
  max-width: 40rem;
  width: 100%;
}
.product-card--2 .product-card__image::before {
  aspect-ratio: 1/1;
  top: 50%;
  left: 50%;
  height: auto;
  max-width: 30rem;
  width: 100%;
  transform: translate(-50%, -50%);
}
.product-card--2 .product-card__image img {
  max-width: 28rem;
}
.product-card--2 .product-card__tags {
  justify-content: center;
}
.product-card--2 .product-card__content {
  text-align: center;
}
.product-card--2 .product-card__title {
  margin-bottom: 0.5rem;
}
.product-card--2 .product-card__price {
  font-size: 5rem;
}
.product-card--2 .product-card__buttons {
  justify-content: center;
}

.quote {
  background-color: var(--color-secondary);
  padding: 13rem 7rem 18rem 7rem;
  position: relative;
  margin-bottom: 10rem;
  margin-top: 5rem;
}
.quote::before, .quote::after {
  content: "";
  background: url("/storage/svg/african-line-overlay-1-white.svg") center center no-repeat;
  background-size: cover;
  opacity: 0.2;
  position: absolute;
  left: 0;
  height: 6.8rem;
  width: 100%;
}
.quote::before {
  top: 2rem;
}
.quote::after {
  bottom: 2rem;
}
.quote__icon {
  background-color: var(--color-primary);
  border: 1.2rem solid #fff;
  border-radius: 99rem;
  color: #fff;
  font-size: 2.5rem;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 8.4rem;
  width: 8.4rem;
}
.quote__text {
  color: var(--color-primary-4);
  font-size: 4rem;
  font-style: italic;
  line-height: 1.6;
  text-align: center;
}
.quote__author {
  flex-flow: column nowrap;
  position: absolute;
  bottom: -26rem;
  left: 50%;
  text-align: center;
  transform: translateX(-50%);
  width: 100%;
  z-index: 5;
}
.quote__author__text {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  flex-flow: row wrap;
  background-color: var(--color-primary);
  color: #fff;
  font-size: 2.5rem;
  gap: 0 2.5rem;
  padding: 1rem 3rem;
  position: relative;
  z-index: 2;
}
.quote__author__text span {
  display: block;
  flex-shrink: 0;
  text-align: center;
}
.quote__author__text span:first-child {
  font-weight: bold;
}
.quote__author__text span:last-child {
  text-transform: uppercase;
}
.quote__author__image {
  display: inline-block;
  height: 34.4rem;
  width: 34.4rem;
  z-index: 1;
}
.quote__author__image::before {
  content: "";
  background-color: #fff;
  border-radius: 99rem;
  position: absolute;
  inset: -3rem;
  z-index: -1;
}
.quote__author__image img {
  border-radius: 99rem;
}

.menu-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 99999;
  transition: opacity 0.4s, visibility 0.4s;
}
.menu-overlay__content {
  background: url("/storage/baobab-background-2-w1920.jpg") center top no-repeat;
  background-size: cover;
  overflow-x: hidden;
  position: absolute;
  inset: 0;
}
.menu-overlay__content::before {
  content: "";
  background: url("/storage/logo/logo-biokide-illustration-style-2-1.svg") center center no-repeat;
  background-size: cover;
  width: 60rem;
  height: 60.5rem;
  z-index: 0;
  position: absolute;
  right: 1%;
  bottom: 1%;
  opacity: 0.06;
  z-index: -1;
}
.menu-overlay__close {
  cursor: pointer;
  position: absolute;
  top: 5%;
  right: 5%;
  z-index: 1;
}
.menu-overlay__close::before {
  transition: 0.4s;
}
.menu-overlay__close:hover::before, .menu-overlay__close:focus-visible::before {
  color: #fff;
}
.menu-overlay__language {
  display: none;
}
.menu-overlay .container {
  grid-template-columns: 35% 65%;
  align-content: center;
}
.menu-overlay .left, .menu-overlay .right {
  padding: 2rem 2rem 0 2rem;
  transition: 0.4s;
}
.menu-overlay .left {
  transform: translateX(-25%);
}
.menu-overlay .left::before {
  content: "";
  background-color: var(--color-primary);
  opacity: 0.25;
  position: absolute;
  bottom: 13.5%;
  right: 0;
  height: 44rem;
  width: 999vw;
  z-index: -1;
}
.menu-overlay .left .product-card-2__image {
  text-align: center;
}
.menu-overlay .left .product-card-2__image img {
  border-radius: 99rem;
  height: clamp(40rem, 70vh, 63rem);
}
.menu-overlay .right {
  transform: translateX(25%);
}
.menu-overlay__navigation {
  margin-bottom: 4rem;
}
.menu-overlay__navigation ul {
  flex-flow: column nowrap;
  height: clamp(35rem, 80vh, 65rem);
}
.menu-overlay__navigation li {
  flex-grow: 1;
  position: relative;
}
.menu-overlay__navigation li::after {
  content: "";
  background-color: var(--color-secondary);
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  height: 3px;
  width: 7.5rem;
}
.menu-overlay__navigation a {
  color: #fff;
  display: block;
  font-size: 4rem;
  font-weight: 300;
  letter-spacing: 0.1em;
  text-transform: uppercase;
}
.menu-overlay__navigation a:hover, .menu-overlay__navigation a:focus-visible {
  color: var(--color-primary);
}

.menu-overlay.active .left, .menu-overlay.active .right {
  transform: translateX(0);
}

.reference {
  margin-bottom: 15rem;
  position: relative;
}
.reference::before {
  content: "";
  border: 5px solid var(--color-primary);
  border-radius: 99rem;
  position: absolute;
  top: -6rem;
  left: 30rem;
  width: 29rem;
  height: 29rem;
  z-index: 1;
}
.reference__image {
  border-radius: 999rem;
  position: relative;
  z-index: 0;
}
.reference__content {
  margin-left: clamp(-50rem, -116.6206896552rem + 0.724137931 * 100vw, -8rem);
  transform: translateY(5rem);
}
.reference__desc {
  font-size: 2.8rem;
}
.reference__desc > * {
  margin-bottom: 4rem;
}
.reference--1::before {
  left: auto;
  right: 30rem;
}
.reference--1 .reference__image {
  order: 2;
}
.reference--1 .reference__content {
  order: 1;
  margin-left: 0;
  margin-right: clamp(-50rem, -116.6206896552rem + 0.724137931 * 100vw, -8rem);
}

.scroll-top {
  width: 6rem;
  height: 6rem;
  border-radius: 999rem;
  background-color: var(--color-primary);
  cursor: pointer;
  position: fixed;
  bottom: 3rem;
  right: 3rem;
  z-index: 99;
  transition: 0.4s;
}
.scroll-top::before {
  color: #fff;
  font-size: 2rem;
}
.scroll-top:hover, .scroll-top:focus {
  background-color: #000;
}
.side-panel {
  display: flex;
  justify-content: flex-end;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
  transition: 0.4s;
}
.side-panel::before {
  content: "";
  background-color: var(--color-tertiary);
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  transition: 0.4s;
}
.side-panel__close {
  background-color: var(--color-primary-4);
  border-radius: 4.5rem;
  cursor: pointer;
  position: absolute;
  top: 1rem;
  left: 1rem;
  height: 4.5rem;
  width: 4.5rem;
  transition: 0.4s;
}
.side-panel__close::before {
  color: var(--color-secondary);
  font-size: 1.6rem;
  transition: 0.4s;
}
.side-panel__close:hover, .side-panel__close:focus-visible {
  background-color: var(--color-secondary);
}
.side-panel__close:hover::before, .side-panel__close:focus-visible::before {
  color: #fff;
}
.side-panel__content {
  background-color: #fff;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  padding-top: 5rem;
  padding-bottom: 3rem;
  position: relative;
  overflow-y: scroll;
  overflow-x: hidden;
  width: 55rem;
  height: 100%;
  z-index: 1;
  transform: translateX(100%);
  transition: 0.4s;
}
.side-panel__content::-webkit-scrollbar-track {
  background: transparent;
}
.side-panel__content::-webkit-scrollbar-thumb {
  background: var(--color-secondary);
}
.side-panel__content::after {
  content: "";
  background: url("/storage/svg/leaf-overlay-1.svg") center center no-repeat;
  background-size: cover;
  width: 33.4rem;
  height: 34.2rem;
  z-index: 0;
  position: absolute;
  top: -4rem;
  right: -2rem;
  transform: scaleX(-1);
  z-index: -1;
}
.side-panel__top {
  flex-shrink: 1;
}
.side-panel__bottom {
  flex-shrink: 0;
}
.side-panel__title {
  letter-spacing: 0.08em;
  margin-bottom: 0.5rem;
  padding: 0 3rem;
  text-align: center;
}
.side-panel__sub {
  font-size: 1.8rem;
  margin-bottom: 3rem;
  padding: 0 3rem;
}
.side-panel__sub span {
  background-color: var(--color-secondary);
  border-radius: 99rem;
  color: #fff;
  display: inline-block;
  font-size: 1.7rem;
  font-weight: bold;
  line-height: 1.6;
  margin: 0 0.75rem;
  padding: 0 0.75rem;
  text-align: center;
  white-space: nowrap;
}
.side-panel__list {
  position: relative;
  overflow-y: scroll;
  height: calc(100vh - 55rem);
  min-height: 15rem;
  max-height: 40rem;
}
.side-panel__list::-webkit-scrollbar-track {
  background: transparent;
}
.side-panel__list__item, .cart__item {
  border-top: 1px solid var(--color-primary-4);
  display: flex;
  gap: 2rem;
  padding: 1.5rem 3rem;
  position: relative;
}
.side-panel__list__item .action, .cart__item .action {
  background-color: transparent;
  border: none;
  color: var(--color-tertiary);
  cursor: pointer;
  font-size: 2.5rem;
  position: absolute;
  top: 1.5rem;
  right: 3rem;
  transition: 0.4s;
}
.side-panel__list__item .action:hover, .cart__item .action:hover, .side-panel__list__item .action:focus-within, .cart__item .action:focus-within {
  color: var(--color-primary);
}
.side-panel__list__item .image a, .cart__item .image a {
  background-color: var(--color-secondary-2);
  border-radius: 99rem;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  padding: 0.5rem;
  position: relative;
  height: 8rem;
  width: 8rem;
}
.side-panel__list__item .image img, .cart__item .image img {
  display: inline-block;
  max-height: 90%;
  max-width: 80%;
}
.side-panel__list__item .image span, .cart__item .image span {
  background-color: var(--color-primary);
  border-radius: 3rem;
  color: #fff;
  font-size: 2rem;
  font-weight: bold;
  position: absolute;
  top: 0;
  right: 0;
  height: 3rem;
  width: 3rem;
}
.side-panel__list__item .title, .cart__item .title {
  color: var(--color-secondary);
  display: block;
  font-size: 2rem;
  font-weight: bold;
  padding-right: 3rem;
  text-transform: uppercase;
}
.side-panel__list__item .sub, .cart__item .sub {
  font-size: 2rem;
}
.side-panel__list__item .price, .cart__item .price {
  color: var(--color-primary);
  font-size: 2.5rem;
  font-weight: bold;
  line-height: 1;
}
.side-panel__summary {
  margin-bottom: 0.5rem;
  padding: 10rem 3rem 0 3rem;
  position: relative;
}
.side-panel__summary::before {
  content: "";
  background: url("/storage/svg/african-line-overlay-1-secondary-white.svg") center center no-repeat;
  background-size: cover;
  width: 100%;
  height: 9.3rem;
  z-index: 0;
  position: absolute;
  top: 0;
  left: 0;
}
.side-panel__summary > span:first-child {
  font-size: 2rem;
}
.side-panel__summary > span:last-child {
  color: var(--color-primary);
  font-size: 4rem;
  font-weight: bold;
  text-align: right;
}
.side-panel__actions {
  display: flex;
  flex-flow: column nowrap;
  gap: 1rem;
  padding: 0 3rem;
}

.side-panel.active::before {
  opacity: 0.85;
}
.side-panel.active .side-panel__content {
  transform: translateX(0);
}

.side-panel--wishlist .side-panel__list {
  min-height: 0;
  max-height: none;
  height: 100%;
}

.cart {
  margin-bottom: 1.5rem;
}
.cart__item {
  border-top: none;
  gap: 3rem;
  padding-bottom: 5rem;
  padding-right: clamp(4rem, -20rem + 0.2 * 100vw, 10rem);
  position: relative;
}
.cart__item::after {
  content: "";
  background: url("/storage/svg/african-line-overlay-1-secondary-white.svg") center center repeat;
  background-size: contain;
  width: 100vw;
  height: 3.3rem;
  z-index: 0;
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translateX(-50%);
}
.cart__item .sub {
  margin-bottom: 1rem;
}
.cart__item .price_wrap {
  margin-left: auto;
}
.cart__item .price {
  font-size: 3.5rem;
}
.cart--2 .cart__item {
  padding: 0;
  margin-bottom: 1.5rem;
}
.cart--2 .cart__item::after {
  display: none;
}
.cart--2 .image a {
  background-color: #fff;
}
.cart--2 .price {
  font-size: 2.8rem;
}

.stable-price {
  margin-bottom: 5rem;
}
.stable-price__item {
  margin-bottom: 1rem;
}
.stable-price__item span:first-child {
  color: var(--color-tertiary);
  font-weight: bold;
}
.stable-price__item span:last-child {
  color: var(--color-secondary);
  font-size: 1.2em;
  font-weight: bold;
  text-align: right;
}
.stable-price__item--feat span:last-child {
  color: var(--color-primary);
  font-size: 1.5em;
}

.footer {
  background-color: var(--color-tertiary);
  background-image: url("/storage/baobab-background-2-w1920.jpg");
  background-position: center top;
  background-repeat: no-repeat;
  position: relative;
  z-index: 5;
}
.footer .footer-top {
  padding: 17rem 5rem 18rem 5rem;
  position: relative;
}
.footer .footer-top::before {
  content: "";
  background-color: var(--color-primary-3);
  position: absolute;
  left: 50%;
  top: 0;
  transform: translate(-50%, -25%);
  height: 16.2rem;
  width: 3px;
}
.footer .footer-top::after {
  content: "";
  background: url("/storage/svg/african-line-overlay-1-white.svg") center center no-repeat;
  background-size: cover;
  width: 100%;
  height: 12.3rem;
  z-index: 0;
  position: absolute;
  left: 0;
  bottom: 0;
  opacity: 0.1;
}
.footer__logo {
  margin: 0 auto 3rem auto;
  width: 27.9rem;
}
.footer__socials {
  margin-bottom: 2rem;
}
.footer__socials ul {
  gap: 6rem;
}
.footer__socials ul li > a {
  color: var(--color-primary);
  font-size: 4rem;
  transition: 0.4s;
}
.footer__socials ul li > a:hover, .footer__socials ul li > a:focus-within {
  color: var(--color-primary-3);
}
.footer__socials--sm ul {
  gap: 3.5rem;
}
.footer__socials--sm ul li > a {
  font-size: 3.5rem;
}
.footer__menu {
  margin-bottom: 5rem;
}
.footer__menu ul {
  flex-flow: row wrap;
  gap: 2rem 4rem;
}
.footer__menu ul li > a {
  color: #fff;
  display: block;
  font-size: 2.5rem;
  position: relative;
  text-transform: uppercase;
  transition: 0.4s;
}
.footer__menu ul li > a:hover, .footer__menu ul li > a:focus-within {
  color: var(--color-primary);
}
.footer__menu ul li:not(:last-child) a::after {
  content: "•";
  color: currentColor;
  font-size: 1em;
  line-height: 1;
  position: absolute;
  top: 50%;
  left: calc(100% + 2rem);
  transform: translate(-50%, -50%);
}
.footer__menu-small ul {
  flex-flow: row wrap;
  gap: 2rem 4rem;
}
.footer__menu-small ul li > a {
  color: #fff;
  display: block;
  font-size: 2rem;
  position: relative;
  transition: 0.4s;
}
.footer__menu-small ul li > a:hover, .footer__menu-small ul li > a:focus-within {
  color: var(--color-primary);
}
.footer__menu-small ul li:not(:last-child) a::after {
  content: "—";
  color: currentColor;
  font-size: 1em;
  line-height: 1;
  position: absolute;
  top: 50%;
  left: calc(100% + 2rem);
  transform: translate(-50%, -50%);
}
.footer .footer-bottom {
  padding: 5rem 5rem 3rem 5rem;
  text-align: center;
}
.footer .footer-bottom p {
  color: var(--color-grey);
  font-size: 1.8rem;
}

.header {
  padding: 3rem 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10;
}
.header__logo {
  display: block;
}
.header__logo img {
  width: 13.6rem;
  height: 15rem;
}
.header__logo--sticky {
  display: none;
}
.header__actions {
  gap: 3.5rem;
}
.header__button {
  background-color: #fff;
  color: var(--color-primary);
}
.header__button:hover, .header__button:focus-within {
  background-color: #000;
  border-color: #000;
  color: #fff;
}

.header.is-sticky {
  background-color: var(--color-tertiary);
  padding: 1rem 0;
  position: fixed;
  animation: fadeBottomSmall 0.4s ease-in-out forwards;
}
.header.is-sticky .container {
  align-items: center;
}
.header.is-sticky .header__logo {
  display: none;
}
.header.is-sticky .header__logo--sticky {
  display: block;
}
.header.is-sticky .header__logo--sticky img {
  height: 8rem;
  width: 8rem;
}

.page-header {
  background-color: var(--color-primary-3);
  margin-bottom: 8rem;
  position: relative;
  z-index: 5;
}
.page-header::before {
  content: "";
  background: url("/storage/svg/baobab-fruit-white.svg") center 40% no-repeat;
  background-size: 74rem 71rem;
  width: 74rem;
  height: 100%;
  z-index: 0;
  position: absolute;
  top: 0;
  left: -15rem;
  opacity: 0.1;
  z-index: -1;
}
.page-header::after {
  content: "";
  background: url("/storage/svg/african-line-overlay-2.svg") center top;
  background-size: contain;
  opacity: 0.08;
  position: absolute;
  top: calc(100% + 6.5rem);
  left: 0;
  width: 5rem;
  height: 100vw;
  z-index: -1;
  transform: rotate(-90deg);
  transform-origin: top left;
}
.page-header__content {
  padding-top: 18rem;
  padding-bottom: 2rem;
}
.page-header__title {
  color: #fff;
  margin: 0;
  height: 13rem;
  max-width: 53%;
}
.page-header__right {
  background-color: var(--color-tertiary);
  position: absolute;
  right: 0;
  top: 0;
  height: calc(100% + 8rem);
  width: 45%;
}
.page-header__right::before {
  content: "";
  background-color: var(--color-tertiary);
  position: absolute;
  top: 0;
  left: 100%;
  height: 100%;
  width: 999rem;
}
.page-header__right::after {
  content: "";
  background: url("/storage/logo/logo-biokide-illustration-style-2-1.svg") top center no-repeat;
  background-size: 60rem 60.5rem;
  width: 190%;
  height: 38.5rem;
  z-index: 0;
  position: absolute;
  left: 0;
  bottom: 0;
  opacity: 0.06;
}

.row-2c {
  grid-template-columns: repeat(2, 1fr);
}

@media (max-width: 47.99em) {
  .row-sm-12 {
    grid-template-columns: 1fr;
    grid-gap: 2rem;
  }
}
.error-page {
  background: url("/storage/baobabs-at-sunrise-background-w1920x896.jpg") center 30rem no-repeat;
  background-color: var(--color-primary-3);
  background-size: cover;
  padding: 24rem 0 15rem 0;
  text-align: center;
}
.error-page > * {
  color: #fff;
}

.page-contact__infos {
  margin-bottom: 10rem;
}
.page-contact__infos .container {
  grid-template-columns: repeat(auto-fit, minmax(37.0555555556rem, 1fr));
}

.page-dashboard {
  padding: 15rem 0;
}

.page-ecommerce .container {
  display: grid;
  grid-gap: 0;
  grid-template-columns: 55% 45%;
}
.page-ecommerce .left {
  padding: 6rem 6rem 10rem 0;
}
.page-ecommerce .right {
  background-color: var(--color-secondary-2);
}
.page-ecommerce .right::before {
  content: "";
  background: url("/storage/svg/african-line-overlay-white.svg") center top;
  background-size: contain;
  opacity: 0.8;
  position: absolute;
  top: 1rem;
  left: 1rem;
  width: 3.5rem;
  height: 100%;
  z-index: -1;
}
.page-ecommerce .right::after {
  content: "";
  background-color: var(--color-secondary-2);
  position: absolute;
  top: 0;
  left: 100%;
  height: 100%;
  width: 999rem;
  z-index: -1;
}
.page-ecommerce .right__content {
  padding: 3rem 0 10rem 6rem;
  position: relative;
}
.page-ecommerce .right__content::before {
  content: "";
  background: url("/storage/svg/leaf-overlay-1-white.svg") center center no-repeat;
  background-size: cover;
  width: 39.8rem;
  height: 40.8rem;
  z-index: 0;
  position: absolute;
  top: -8rem;
  right: -14rem;
  opacity: 0.6;
  transform: scaleX(-1);
  z-index: -1;
}
.page-ecommerce .right--1 .right__content {
  padding-top: 10rem;
}

.section-banner {
  background-color: var(--color-primary-3);
}
.section-banner .home-banner-slider {
  height: 75rem;
  width: 100%;
}
.section-banner__slide {
  background-color: var(--color-primary-3);
  overflow: hidden;
  position: relative;
}
.section-banner__slide__overlay-1 {
  border-radius: 999rem;
  position: absolute;
  top: 12%;
  left: calc(50% - 9.25rem);
  height: 18.5rem;
  width: 18.5rem;
  transform: translateX(-50%);
  z-index: 5;
}
.section-banner__slide .container {
  display: grid;
  grid-gap: 0;
  grid-template-columns: repeat(2, 1fr);
}
.section-banner__slide .left {
  padding: 22rem 4rem 4rem calc(50vw - 75rem);
  position: relative;
}
.section-banner__slide .left::before {
  content: "";
  background: url("/storage/svg/baobab-fruit-white.svg") center center no-repeat;
  background-size: cover;
  width: 74rem;
  height: 71rem;
  z-index: 0;
  position: absolute;
  top: -10%;
  left: -30%;
  opacity: 0.13;
  z-index: -2;
}
.section-banner__slide .right {
  background-color: var(--color-tertiary);
  padding: 14rem 4rem 4rem 4rem;
}
.section-banner__slide .right::before {
  content: "";
  background-color: var(--color-tertiary);
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 999vw;
  z-index: -1;
}
.section-banner__slide .right::after {
  content: "";
  background: url("/storage/logo/logo-biokide-illustration-style-2-1.svg") center center no-repeat;
  background-size: cover;
  width: 60rem;
  height: 60.5rem;
  z-index: 0;
  position: absolute;
  right: -35%;
  bottom: -5%;
  opacity: 0.06;
  z-index: -1;
}
.section-banner__slide .right img {
  width: 80%;
  max-width: 47.5rem;
}
.section-banner .banner-title {
  color: #fff;
  font-size: clamp(6rem, 1.2rem + 0.04 * 100vw, 7.2rem);
  letter-spacing: 0.08em;
  line-height: 1;
  margin-bottom: 4rem;
}
.section-banner .banner-title span {
  display: inline-block;
}
.section-banner .banner-title span:last-child {
  font-size: 1.3em;
  font-weight: bold;
  position: relative;
  white-space: nowrap;
}
.section-banner .banner-title span:last-child::before {
  content: "";
  background: url("/storage/cream-dash-1.png") center center no-repeat;
  background-size: cover;
  width: 5em;
  height: 2.5em;
  z-index: 0;
  position: absolute;
  right: -2%;
  bottom: -25%;
  transform: translateY(50%);
  z-index: -1;
}
.section-banner .banner-desc {
  color: #fff;
  font-size: clamp(2.5rem, 0.5rem + 0.0166666667 * 100vw, 3rem);
  margin-bottom: 6rem;
}

.section-intro {
  background-color: var(--color-primary-3);
  background-image: url("/storage/baobabs-at-sunrise-background-w1920x896.jpg");
  background-position: center bottom;
  background-repeat: no-repeat;
  padding: 25rem 0;
  position: relative;
}
.section-intro::before {
  content: "";
  background: url("/storage/svg/african-line-overlay-1-white.svg") center center no-repeat;
  background-size: cover;
  width: 100%;
  height: 17rem;
  z-index: 0;
  position: absolute;
  top: 3rem;
  left: 50%;
  transform: translateX(-50%);
  opacity: 0.2;
}
.section-intro::after {
  content: "";
  background: url("/storage/lady-in-circle-effect-w500.png") center center no-repeat;
  background-size: cover;
  width: 41.9rem;
  height: 47.1rem;
  z-index: 0;
  position: absolute;
  left: 65%;
  bottom: 0;
  transform: translateY(60%);
}
.section-intro .container {
  grid-template-columns: 25% 1fr;
}
.section-intro .left {
  text-align: center;
}
.section-intro .right {
  text-align: center;
}
.section-intro .right * {
  color: #fff;
}
.section-intro__block-1 {
  flex-flow: row wrap;
  gap: 2rem 8rem;
  margin: 4rem 0;
}

.section-products {
  overflow: hidden;
  padding: 10rem 0;
}
.section-products::before {
  content: "";
  background: url("/storage/svg/leaf-overlay-1.svg") center center no-repeat;
  background-size: cover;
  width: 33.4rem;
  height: 34.2rem;
  z-index: 0;
  position: absolute;
  top: -4rem;
  left: 6rem;
  z-index: -1;
}
.section-products::after {
  content: "";
  background: url("/storage/svg/leaf-overlay-2.svg") center center no-repeat;
  background-size: cover;
  width: 55rem;
  height: 32rem;
  z-index: 0;
  position: absolute;
  right: 2rem;
  bottom: -5rem;
  z-index: -1;
}

.section-respect {
  background-color: var(--color-primary-3);
  background-image: url("/storage/left-nature-section-w1920.jpg");
  background-position: center bottom;
  background-repeat: no-repeat;
  padding: 10rem 0;
}
.section-respect::before {
  content: "";
  background: url("/storage/svg/africa-map-white.svg") center center no-repeat;
  background-size: cover;
  width: 93.3rem;
  height: 104.9rem;
  z-index: 0;
  position: absolute;
  top: -5rem;
  right: -10rem;
  opacity: 0.13;
  z-index: -1;
}
.section-respect .section-title {
  position: relative;
  z-index: 1;
}
.section-respect .section-content {
  display: grid;
  grid-gap: 10rem;
  grid-template-columns: 3fr 5fr;
  margin-top: -10rem;
}
.section-respect .section-content .left {
  text-align: center;
}
.section-respect .section-content .right {
  color: #fff;
  padding-top: 15rem;
}

.section-quote {
  padding: 10rem 0;
  position: relative;
  z-index: 1;
}
.section-quote::before, .section-quote::after {
  content: "";
  background: url("/storage/svg/baobab-draw.svg") center top no-repeat;
  background-size: cover;
  opacity: 0.65;
  position: absolute;
  bottom: 0;
  width: 93.6rem;
  height: 80rem;
  z-index: -1;
}
.section-quote::before {
  left: -35rem;
}
.section-quote::after {
  right: -35rem;
}
.section-quote__inauguration {
  display: flex;
  align-items: stretch;
  justify-content: center;
  gap: 2.5rem;
  margin-top: 2.5rem;
}
.section-quote .sqi-left,
.section-quote .sqi-right {
  text-align: center;
}
.section-quote .sqi-center {
  max-width: 50rem;
}
.section-quote .sqi-center > * {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.section-references, .product-single-infos {
  background-color: var(--color-primary-4);
  overflow: hidden;
  padding: 10rem 0;
}
.section-references::before, .product-single-infos::before, .section-references::after, .product-single-infos::after {
  content: "";
  background: url("/storage/svg/african-line-overlay-2.svg") center top;
  background-size: contain;
  opacity: 0.08;
  position: absolute;
  top: 2rem;
  width: 6.5rem;
  height: 100%;
  z-index: -1;
}
.section-references::before, .product-single-infos::before {
  left: 2rem;
}
.section-references::after, .product-single-infos::after {
  right: 2rem;
  transform: scaleX(-1);
}

.page-login {
  background: url("/storage/beautiful-natural-young-woman-portrait-reversed-w1920.jpg") calc(30vw - 71rem) center no-repeat;
  background-size: auto 100%;
  min-height: 80rem;
}
.page-login .container {
  grid-template-columns: 1fr minmax(60rem, 45%);
}
.page-login .content {
  display: flex;
  justify-content: flex-end;
  grid-column: 2/-1;
  padding-top: 25rem;
}
.page-login .content::before {
  content: "";
  background-color: var(--color-tertiary);
  position: absolute;
  top: 0;
  left: 0;
  height: 41rem;
  width: 999rem;
  z-index: -2;
}
.page-login .content::after {
  content: "";
  background: url("/storage/logo/logo-biokide-illustration-style-2-1.svg") top center no-repeat;
  background-size: 60rem 60.5rem;
  width: 190%;
  height: 38.5rem;
  z-index: 0;
  position: absolute;
  top: 41rem;
  left: 0;
  opacity: 0.06;
  transform: translateY(-100%);
  z-index: -1;
}
.page-login .content_wrap {
  background-color: var(--color-secondary-2);
  padding: 7rem 5rem;
  width: calc(100% - 4rem);
}
.page-login .content_wrap::before {
  content: "";
  background-color: var(--color-secondary-2);
  position: absolute;
  top: 0;
  left: 100%;
  height: 100%;
  width: 999rem;
  z-index: -1;
}
.page-login .content_wrap::after {
  content: "";
  background: url("/storage/svg/leaf-overlay-1-white.svg") center bottom no-repeat;
  background-size: 39.8rem 40.8rem;
  width: 39.8rem;
  height: 32.8rem;
  z-index: 0;
  position: absolute;
  top: 0;
  right: -14rem;
  opacity: 0.6;
  transform: scaleX(-1);
  z-index: -1;
}
.page-login__form {
  padding-bottom: 6rem;
  margin-bottom: 2rem;
  position: relative;
}
.page-login__form::after {
  content: "";
  background: url("/storage/svg/african-line-overlay-1-white.svg") left center no-repeat;
  background-size: contain;
  width: 100vw;
  height: 5rem;
  z-index: 0;
  position: absolute;
  left: -5rem;
  bottom: 0;
}

.page-content {
  padding: 8rem 0 14rem 0;
}

.page-product-single {
  padding-top: 8rem;
}

.product-single-top {
  margin-bottom: 12rem;
}
.product-single-top .container {
  display: grid;
  grid-gap: clamp(2rem, -22rem + 0.2 * 100vw, 8rem);
  grid-template-columns: minmax(45rem, 1fr) 1.8fr;
}

.product-single-infos {
  overflow: visible;
  padding-top: 0;
}
.product-single-infos .collapse__tabs {
  transform: translateY(-50%);
}

.product-single__title {
  color: var(--color-secondary);
  font-size: 5.5rem;
  font-weight: normal;
  margin-bottom: 1.5rem;
  text-transform: uppercase;
}
.product-single__price {
  margin-bottom: 2rem;
}
.product-single__price span {
  color: var(--color-primary);
  display: inline-block;
  font-size: 7rem;
  font-weight: bold;
  position: relative;
}
.product-single__price span::after {
  content: "";
  background-color: var(--color-primary);
  position: absolute;
  top: 50%;
  left: calc(100% + 5rem);
  height: 3px;
  width: 100vw;
}

.product-single-slider__first {
  aspect-ratio: 1;
  margin: 0 auto;
  max-height: 50rem;
}
.product-single-slider__first::before {
  content: "";
  aspect-ratio: 1;
  background-color: var(--color-secondary-2);
  border-radius: 99rem;
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 90%;
  z-index: -1;
  transform: translateX(-50%);
}
.product-single-slider__first img {
  max-width: 90%;
  max-height: 42rem;
}

.product-single-slider__item {
  aspect-ratio: 1;
  border-radius: 999rem;
  display: block;
  overflow: hidden;
  margin: 0 auto;
  max-height: 50rem;
}
.products-grid {
  display: grid;
  grid-gap: 6rem;
  grid-template-columns: repeat(auto-fit, minmax(35.2666666667rem, 1fr));
}

.page-thank-you {
  background: url("/storage/baobabs-at-sunrise-background-w1920x896.jpg") center 38rem no-repeat;
  background-color: var(--color-primary-3);
  background-size: cover;
  color: #fff;
  padding: 16rem 0 15rem 0;
  text-align: center;
}

.thanks-img {
  aspect-ratio: 1/1;
  display: inline-block;
  margin-bottom: 6rem;
  position: relative;
  width: 26rem;
}
.thanks-img img {
  border-radius: 99rem;
}
.thanks-img i {
  background-color: var(--color-primary);
  border: 1rem solid var(--color-primary-3);
  border-radius: 99rem;
  color: #fff;
  font-size: 3.5rem;
  line-height: 0;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 50%);
  height: 8.5rem;
  width: 8.5rem;
}
.thanks-img::after {
  content: "";
  background-color: #fff;
  position: absolute;
  left: 50%;
  bottom: 90%;
  height: 100vh;
  width: 3px;
  transform: translateX(-50%);
}

@media (max-width: 96.86em) {
  .section-banner__slide .left {
    padding: 22rem 4rem 4rem 2.5rem;
  }
}
@media (max-width: 61.99em) {
  .menu-overlay .container {
    grid-template-columns: 45% 55%;
    grid-gap: 0;
  }
  .reference {
    flex-flow: column nowrap;
    align-items: center;
    margin-bottom: 5rem;
  }
  .reference::before {
    display: none;
  }
  .reference__content, .reference--1 .reference__content {
    order: 2;
    margin: -5rem 0 0 0;
    transform: none;
  }
  .reference--1 .reference__image {
    order: 1;
  }
  .page-ecommerce.page-cart .container {
    grid-template-columns: 60% 40%;
    grid-gap: 0;
  }
  .page-ecommerce.page-cart .left {
    padding: 1.5rem 0 3rem 0;
  }
  .page-ecommerce.page-cart .right--1 .right__content {
    padding-top: 5rem;
  }
  .page-header__right {
    display: none;
  }
  .page-header__title {
    max-width: 100%;
  }
  .section-intro {
    padding: 25rem 0 10rem 0;
    position: relative;
  }
  .section-intro::after {
    display: none;
  }
  .section-intro .container {
    grid-template-columns: 1fr;
    grid-gap: 0;
  }
  .section-intro .left {
    order: 2;
  }
  .section-intro .left img {
    max-height: 55rem;
  }
  .section-intro .right {
    order: 1;
  }
  .section-quote__inauguration {
    flex-flow: row wrap;
    margin: 30px auto 0 auto;
    max-width: 75rem;
  }
  .section-quote .sqi-center {
    flex-grow: 1;
    order: 1;
    width: calc(100vw - 4rem);
  }
  .section-quote .sqi-left {
    flex: 1 1 40%;
    order: 2;
  }
  .section-quote .sqi-right {
    flex: 1 1 40%;
    order: 3;
  }
  .page-ecommerce.page-checkout .container {
    display: block;
  }
  .page-ecommerce.page-checkout .left {
    padding: 1.5rem 0 3rem 0;
  }
  .page-ecommerce.page-checkout .right::after {
    left: 50%;
    transform: translateX(-50%);
    z-index: -5;
  }
  .page-ecommerce.page-checkout .right__content {
    padding: 3rem 1.5rem 7rem 1.5rem;
    margin: 0 auto;
    max-width: 60rem;
  }
  .page-ecommerce.page-checkout .page-checkout__leftButtons {
    display: none;
  }
}
@media (max-width: 58.74em) {
  .section-banner .home-banner-slider {
    height: calc(100rem + 25vw);
  }
  .section-banner__slide__overlay-1 {
    display: none;
  }
  .section-banner__slide .container {
    display: block;
  }
  .section-banner__slide .left {
    padding-left: 0;
    padding-right: 0;
    margin-bottom: 6vw;
  }
  .section-banner__slide .right {
    padding: 0;
    height: 30rem;
  }
  .section-banner__slide .right img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 37rem;
  }
  .section-banner__slide .banner-title {
    font-size: clamp(5rem, 1.6666666667rem + 0.0666666667 * 100vw, 9rem);
    text-align: center;
  }
  .section-banner__slide .banner-title span:last-child {
    white-space: normal;
  }
  .section-banner__slide .banner-desc {
    font-size: clamp(2.2rem, 0.7rem + 0.03 * 100vw, 4rem);
    text-align: center;
  }
  .section-banner__slide .btn-wrap {
    justify-content: center;
  }
}
@media (max-width: 47.99em) {
  .card-image__image {
    display: none;
  }
  .card-image__content {
    padding: 5rem 5rem 10rem;
    margin-left: 0;
  }
  .menu-overlay .container {
    grid-template-columns: 1fr;
    grid-gap: 0;
  }
  .menu-overlay .left {
    display: none;
  }
  .section-respect .section-content {
    grid-template-columns: 1fr;
    grid-gap: 0;
    margin-top: -4rem;
  }
  .section-respect .section-content .left img {
    max-height: 60rem;
  }
  .section-respect .section-content .right {
    padding-top: 2rem;
  }
  .product-single-top .container {
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 2.5rem;
  }
  .product-single-top .left {
    width: 100%;
  }
  .product-single-slider {
    max-width: calc(100vw - 5rem);
    width: 100%;
  }
  .page-ecommerce.page-cart .container {
    display: block;
  }
  .page-ecommerce.page-cart .right::after {
    left: 50%;
    transform: translateX(-50%);
    z-index: -5;
  }
  .page-ecommerce.page-cart .right__content {
    padding: 1.5rem 1.5rem 7rem 1.5rem;
    margin: 0 auto;
    max-width: 60rem;
  }
}
@media (max-width: 38.115em) {
  .header__action {
    display: none;
  }
  .bottom-navigation {
    display: flex;
  }
}
@media (max-width: 35.49em) {
  .section-title__main,
  .h1, h1 {
    font-size: 7rem;
  }
  .h2, h2 {
    font-size: 5rem;
  }
  .h3, h3 {
    font-size: 4rem;
  }
  .h4, h4 {
    font-size: 3.5rem;
  }
  .product-card {
    gap: 1rem;
    flex-flow: column wrap;
  }
  .product-card .product-card__image {
    padding-left: 0;
    height: 35rem;
    max-width: 40rem;
    width: 100%;
  }
  .product-card .product-card__image::before {
    aspect-ratio: 1/1;
    top: 50%;
    left: 50%;
    height: auto;
    max-width: 30rem;
    width: 100%;
    transform: translate(-50%, -50%);
  }
  .product-card .product-card__image img {
    max-width: 28rem;
  }
  .product-card .product-card__tags {
    justify-content: center;
  }
  .product-card .product-card__content {
    text-align: center;
  }
  .product-card .product-card__title {
    margin-bottom: 0.5rem;
    min-height: 0;
  }
  .product-card .product-card__price {
    font-size: 5rem;
  }
  .product-card .product-card__buttons {
    justify-content: center;
  }
  .scroll-top {
    bottom: 9rem;
  }
  .menu-overlay__language {
    display: block;
    text-align: center;
  }
  .menu-overlay .language-switcher {
    display: inline-block;
  }
  .page-login .container {
    display: block;
  }
  .page-login .container .content::before {
    left: -200rem;
  }
  .page-login .container .content_wrap {
    padding: 7rem 0;
    width: 100%;
  }
  .page-login .container .content_wrap::before {
    left: -200rem;
  }
}
@media (max-width: 29.99em) {
  .cart__item {
    gap: 1rem;
    flex-flow: column nowrap;
    align-items: center;
    text-align: center;
  }
  .cart__item .image {
    width: 100%;
  }
  .cart__item .title {
    padding-right: 0;
  }
  .cart__item .price_wrap {
    margin-left: 0;
  }
  .header .language-switcher {
    display: none;
  }
}
.u-bg-secondary-2 {
  background-color: var(--color-secondary-2);
}

.u-color-primary {
  color: var(--color-primary) !important;
}

.u-color-secondary {
  color: var(--color-secondary) !important;
}

.u-color-tertiary {
  color: var(--color-tertiary) !important;
}

.u-color-danger {
  color: var(--color-danger) !important;
}

.u-color-white {
  color: #fff !important;
}

.u-col-grid-full {
  grid-column: 1/-1 !important;
}

.u-text-center {
  text-align: center !important;
}

.u-text-normal {
  font-weight: normal !important;
}

.u-text-bold {
  font-weight: bold !important;
}

.u-text-right {
  text-align: right !important;
}

.u-text-07 {
  font-size: 0.7em !important;
}

.u-text-09 {
  font-size: 0.9em !important;
}

.u-text-12 {
  font-size: 1.2em !important;
}

.u-text-sm {
  font-size: 1.6rem !important;
}

.u-full-width {
  width: 100% !important;
}

/* -----------------------------------------------
 *  Auto generated helpers
 * -> You should not modify that part
 ------------------------------------------------- */