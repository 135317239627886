.scroll-top {
    @extend %flex-center-center;
    @extend %is-hidden;
    @include shape-elt($width: 6rem, $rounded: 'round');

    background-color: var(--color-primary);
    cursor: pointer;
    position: fixed;
    bottom: 3rem;
    right: 3rem;
    z-index: 99;
    transition: $transition;

    &::before {
        @extend %fig-arrow-to-top;
        color: #fff;
        font-size: 2rem;
    }
    
    &:hover, &:focus {
        background-color: #000;
    }

    &.active {
        @extend %is-visible;
    }
}