.collapse {

    // Variant 1
    &--1 {
        .collapse__item {
            background-color: #fff;
            border: 2px solid var(--color-tertiary-3);
            border-radius: 99rem;
            color: var(--color-primary);
            cursor: pointer;
            display: inline-block;
            font-family: $heading-font;
            font-size: 5rem;
            padding: 1rem 5rem;
            transition: $transition;

            &.active {
                background-color: var(--color-primary);
                color: #fff;
            }
        }
    }
}