// Background helpers
.u-bg-secondary-2 { background-color: var(--color-secondary-2); }

// Color helpers
.u-color-primary { color: var(--color-primary) !important; }
.u-color-secondary { color: var(--color-secondary) !important; }
.u-color-tertiary { color: var(--color-tertiary) !important; }
.u-color-danger { color: var(--color-danger) !important; }
.u-color-white { color: #fff !important; }

// Grid helpers
.u-col-grid-full { grid-column: 1 / -1 !important; }

// Text helper
.u-text-center { text-align: center !important; }
.u-text-normal { font-weight: normal !important; }
.u-text-bold { font-weight: bold !important; }
.u-text-right { text-align: right !important; }
.u-text-07 { font-size: .7em !important; }
.u-text-09 { font-size: .9em !important; }
.u-text-12 { font-size: 1.2em !important; }
.u-text-sm { font-size: 1.6rem !important; }

// Width helper
.u-full-width { width: 100% !important; }




/* -----------------------------------------------
 *  Auto generated helpers
 * -> You should not modify that part
 ------------------------------------------------- */

// Spacing helpers
@each $value, $abbreviations in $spacings {
    @if (type-of($value) == 'string') {
        @each $abbreviation in $abbreviations {
            .u-#{$abbreviation}-#{$value} {
                @extend %#{$abbreviation}-#{$value}-important;
            }
        }
    }
}