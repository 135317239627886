.bottom-navigation {
    background-color: var(--color-tertiary);
    display: none;
    align-items: center;
    justify-content: space-around;
    gap: 1.5rem;
    padding: 1.5rem;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 10;
}