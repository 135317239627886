.pellet {
    border-radius: 99rem;
    display: inline-block;
    font-size: 1.8rem;
    font-weight: bold;
    line-height: 1;
    padding: .5rem 1.5rem;
    text-transform: uppercase;
}
.pellet-primary {
    @extend .pellet;
    background-color: var(--color-primary);
    color: #fff;
}
.pellet--lg {
    font-size: 2.2rem;
    padding: 1rem 2.5rem;
}