.button-primary {
    @extend %button;
    background-color: var(--color-primary);
    border-color: var(--color-primary);
    color: #fff;
    
    &:hover, &:focus-visible {
        background-color: transparent;
        color: var(--color-primary);
    }
}
.button-primary-outline {
    @extend %button;
    background-color: transparent;
    border-color: var(--color-primary);
    color: var(--color-primary);
    
    &:hover, &:focus-visible {
        background-color: var(--color-primary);
        color: #fff;
    }
}

.button-secondary-2 {
    @extend %button;
    background-color: var(--color-secondary-2);
    border-color: var(--color-secondary-2);
    color: var(--color-secondary);
    
    &:hover, &:focus-visible {
        background-color: var(--color-secondary);
        border-color: var(--color-secondary);
        color: #fff;
    }
}

.button-secondary-outline {
    @extend %button;
    background-color: transparent;
    border-color: var(--color-secondary);
    color: var(--color-secondary);
    
    &:hover, &:focus-visible {
        background-color: var(--color-secondary);
        border-color: var(--color-secondary);
        color: #fff;
    }
}

.button-tertiary {
    @extend %button;
    background-color: var(--color-tertiary);
    border-color: var(--color-tertiary);
    color: #fff;
    
    &:hover, &:focus-visible {
        background-color: transparent;
        color: var(--color-tertiary);
    }
}

.button-tertiary-outline {
    @extend %button;
    background-color: transparent;
    border-color: var(--color-tertiary);
    color: var(--color-tertiary);
    
    &:hover, &:focus-visible {
        background-color: var(--color-tertiary);
        color: #fff;
    }
}


.button-white-outline {
    @extend %button;
    background-color: transparent;
    border-color: #fff;
    color: #fff;
    
    &:hover, &:focus-visible {
        background-color: #fff;
        color: var(--color-dark);
    }
}

.button--xs {
    font-size: 2rem;
    padding: .5rem 1.5rem;
}
.button--sm {
    font-size: 2.2rem;
    padding: 1rem 2.5rem;
}
.button--lg {
    font-size: 2.5rem;
    padding: 2rem 4rem;
}


.button-icon-primary {
    @extend %button-icon;
    border-color: var(--color-primary);
    color: var(--color-primary);

    &:hover, &:focus-visible {
        background-color: var(--color-primary);
        border-color: var(--color-primary);
        color: #fff;
    }
}
.button-icon-secondary-h {
    @extend %button-icon;
    background-color: transparent;
    border-color: transparent;
    color: var(--color-secondary);

    &:hover, &:focus-visible {
        background-color: var(--color-secondary);
        border-color: var(--color-secondary);
        color: #fff;
    }
}
.button-icon--sm {
    border-radius: 4.5rem;
    font-size: 2.5rem;
    height: 4.5rem;
    width: 4.5rem;
}


.button-action {
    @extend %flex-center-center;
    background-color: transparent;
    border: none;
    cursor: pointer;
    font-size: 2.8rem;
    gap: 1rem;
    position: relative;

    i {
        color: #fff;
        transition: $transition;
    }

    &__pellet {
        @extend %flex-center-center;
        background-color: var(--color-primary);
        border-radius: 3rem;
        color: #fff;
        font-size: 1.6rem;
        font-weight: bold;
        position: absolute;
        top: -1.2rem;
        right: -1.2rem;
        height: 2.5rem;
        width: 2.5rem;
    }

    &__text { 
        color: #fff;
        font-size: 1.8rem; 
        font-weight: 500;
        transition: $transition;
    }

    &:hover, &:focus-within {
        i, .button-action__text { color: var(--color-primary); }

    }
}


.button-loading {
    position: relative;
    &::before {
        @extend %fig-loading;
        @extend %is-hidden;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        animation: spinningCenter 3s linear infinite;
    }

    &.is-loading {
        & > * { @extend %is-hidden; }
        &::before { @extend %is-visible; }
    }
}


.btn-wrap {
    @extend %flex-align-center;
    flex-flow: row wrap;
    gap: 1.5rem 3rem;

    &--between {
        justify-content: space-between;
    }
    &--center {
        justify-content: center;
    }
    &--end {
        justify-content: flex-end;
    }
}