@keyframes fadeBottomSmall {
    from { opacity: 0; transform: translateY(-1rem); }
    to { opacity: 1; transform: translateY(0); }
}

@-webkit-keyframes fadeInUp {
    from {
      opacity: 0;
      -webkit-transform: translate3d(0, 5rem, 0);
      transform: translate3d(0, 5rem, 0);
      visibility: visible;
    }
  
    to {
      opacity: 1;
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }
  }
  @keyframes fadeInUp {
    from {
      opacity: 0;
      -webkit-transform: translate3d(0, 5rem, 0);
      transform: translate3d(0, 5rem, 0);
      visibility: visible;
    }
  
    to {
      opacity: 1;
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }
}
.fadeInUp, .animated.fadeInUp {
    animation: fadeInUp $animation-duration forwards;
}



@-webkit-keyframes fadeInRight {
    from {
      opacity: 0;
      -webkit-transform: translate3d(5rem, 0, 0);
      transform: translate3d(5rem, 0, 0);
      visibility: visible;
    }
  
    to {
      opacity: 1;
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }
  }
  @keyframes fadeInRight {
    from {
      opacity: 0;
      -webkit-transform: translate3d(5rem, 0, 0);
      transform: translate3d(5rem, 0, 0);
      visibility: visible;
    }
  
    to {
      opacity: 1;
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }
}
.fadeInRight, .animated.fadeInRight {
    animation: fadeInRight $animation-duration forwards;
}


@-webkit-keyframes fadeInLeft {
    from {
      opacity: 0;
      -webkit-transform: translate3d(-5rem, 0, 0);
      transform: translate3d(-5rem, 0, 0);
      visibility: visible;
    }
  
    to {
      opacity: 1;
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }
  }
  @keyframes fadeInLeft {
    from {
      opacity: 0;
      -webkit-transform: translate3d(-5rem, 0, 0);
      transform: translate3d(-5rem, 0, 0);
      visibility: visible;
    }
  
    to {
      opacity: 1;
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      visibility: visible;
    }
}
.fadeInLeft, .animated.fadeInLeft {
    animation: fadeInLeft $animation-duration forwards;
}

@keyframes growTransp {
  from { opacity: 1; }
  to {
      opacity: 0;
      height: 2rem;
      width: 2rem;
  }
}

@keyframes spinning {
    from { transform: rotate(0); }
    to { transform: rotate(360deg); }
}
@keyframes spinningCenter {
    from { transform: translate(-50%,-50%) rotate(0); }
    to { transform: translate(-50%,-50%) rotate(360deg); }
}