.language-switcher {
    position: relative;

    &__current {
        @extend %flex-center-center;
        background-color: #000;
        border-radius: 6rem;
        color: #fff;
        cursor: pointer;
        font-weight: bold;
        position: relative;
        text-transform: uppercase;
        height: 6rem;
        width: 6rem;
        transition: $transition;
        z-index: 1;

        img { 
            position: absolute; 
            bottom: 0;
            left: 50%;
            transform: translate(-50%,50%);
            width: 2rem !important;
        }
    }

    &__dropdown {
        @extend %is-hidden;
        background-color: #000;
        position: absolute;
        top: 100%;
        right: -1rem;
        width: 20rem;
        transition: $transition;

        li > a {
            color: #fff;
            display: block;
            padding: 2rem;
            transition: $transition;

            &:hover { background-color: #222; }
        }
    }

    &:hover {
        .language-switcher__current {
            background-color: var(--color-primary);
        }
        .language-switcher__dropdown {
            @extend %is-visible;
            transform: translateY(1rem);
        }
    }

    &--hover2 {
        .language-switcher__dropdown {
            top: auto;
            bottom: 100%;
            left: 50%;
            transform: translateX(-50%);
        }

        &:hover {
            .language-switcher__dropdown {
                transform: translate(-50%,-1rem);
            }
        }
    }
}