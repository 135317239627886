.footer {
    background-color: var(--color-tertiary);
    background-image: url('/storage/baobab-background-2-w1920.jpg');
    background-position: center top;
    background-repeat: no-repeat;
    position: relative;
    z-index: 5;

    .footer-top {
        padding: 17rem 5rem 18rem 5rem;
        position: relative;
        &::before {
            content: "";
            background-color: var(--color-primary-3);
            position: absolute;
            left: 50%;
            top: 0;
            transform: translate(-50%,-25%);
            height: 16.2rem;
            width: 3px;
        }

        &::after {
            @include before-after-background(
                $url: '/storage/svg/african-line-overlay-1-white.svg',
                $bottom: 0,
                $left: 0,
                $width: 100%,
                $height: 12.3rem,
            );
            opacity: .1;
        }
    }

    &__logo {
        margin: 0 auto 3rem auto;
        width: 27.9rem;
    }

    &__socials {
        margin-bottom: 2rem;
        ul {
            @extend %flex-center-center;
            gap: 6rem;
            li > a { 
                color: var(--color-primary);
                font-size: 4rem; 
                transition: $transition;

                &:hover, &:focus-within {
                    color: var(--color-primary-3);
                }
            }
        }

        &--sm {
            ul {
                gap: 3.5rem;
                li > a {
                    font-size: 3.5rem;
                }
            }
        }
    }

    &__menu {
        margin-bottom: 5rem;
        ul {
            @extend %flex-center-center;
            flex-flow: row wrap;
            gap: 2rem 4rem;
            li > a {
                color: #fff;
                display: block;
                font-size: 2.5rem;
                position: relative;
                text-transform: uppercase;
                transition: $transition;

                &:hover, &:focus-within {
                    color: var(--color-primary);
                }
            }
            li:not(:last-child) a {
                &::after {
                    content: "•";
                    color: currentColor;
                    font-size: 1em;
                    line-height: 1;
                    position: absolute;
                    top: 50%;
                    left: calc(100% + 2rem);
                    transform: translate(-50%,-50%);
                }
            }
        }
    }
    
    &__menu-small {
        ul {
            @extend %flex-center-center;
            flex-flow: row wrap;
            gap: 2rem 4rem;
            li > a {
                color: #fff;
                display: block;
                font-size: 2rem;
                position: relative;
                transition: $transition;

                &:hover, &:focus-within {
                    color: var(--color-primary);
                }
            }
            li:not(:last-child) a {
                &::after {
                    content: "—";
                    color: currentColor;
                    font-size: 1em;
                    line-height: 1;
                    position: absolute;
                    top: 50%;
                    left: calc(100% + 2rem);
                    transform: translate(-50%,-50%);
                }
            }
        }
    }

    .footer-bottom {
        padding: 5rem 5rem 3rem 5rem;
        text-align: center;
        p {
            color: var(--color-grey);
            font-size: 1.8rem;
        }
    }
}