@mixin background-image($url, $gradient: 'null', $position: center center, $repeat: no-repeat, $size: cover) {

    @if ($gradient != 'null') {
        background: $gradient, url($url) $position $repeat;
    } @else {
        background: url($url) $position $repeat;
    }
    background-size: $size;
}

@mixin background-element($url, $width, $height) {
    background: url($url) center center no-repeat;
    background-size: cover;
    width: $width;
    height: $height;
}

@mixin before-after-background($url, $position: center center, $repeat: no-repeat, $size: cover, $width: 100%, $height: 100%, $top: 'null', $left: 'null', $right: 'null', $bottom: 'null', $self-centered: 'null') {
    content: "";
    background: url($url) $position $repeat;
    background-size: $size;
    width: $width;
    height: $height;
    z-index: 0;

    @include positionCoords(absolute, $top, $left, $right, $bottom);
    @include positionTranslate($self-centered);
}

@mixin before-after-background-shape($color, $width, $top: 'null', $left: 'null', $right: 'null', $bottom: 'null', $height: 'null', $rounded: 'false', $opacity: 1, $self-centered: 'null') {
    content: "";
    background-color: $color;

    @include positionCoords(absolute, $top, $left, $right, $bottom);
    @include positionTranslate($self-centered);
    @include squarify($width, $height);

    @if ($opacity != 1) {
        opacity: $opacity;
    }

    @if ($rounded != 'false') {
        border-radius: $width;
    } 
}