/**
 * ------------------------------------------------
 * CSS Variables
 * ------------------------------------------------
 */

:root {
    --color-primary: #D0A346;
    --color-primary-2: #C1A55C;
    --color-primary-3: #DCC18C;
    --color-primary-4: #F1E8D4;
    --color-secondary: #bc6a42;
    --color-secondary-2: #FAF4F2;
    --color-tertiary: #4B2A1A;
    --color-tertiary-2: #E9CDBF;
    --color-dark: #000;
    --color-grey: #B2A893;
    --color-grey-2: #888;
    --color-grey-3: #F5F5F5;
    --color-white: #fff;
    --color-danger: #c93d30;
    --color-success: #27ae60;
    --font-family-1st: 'Karla', sans-serif; 
    --font-family-2nd: 'Luxerie Regular', sans-serif; 
    --font-family-3rd: 'Roustel Regular', sans-serif; 
}

/**
 * ------------------------------------------------
 * SASS Config Variables
 * ------------------------------------------------
 */

/// Body config
$body-color: var(--color-grey);
$body-font: var(--font-family-1st);
$body-size: 2rem;
$body-weight: 300;
$body-line-height: 1.4  ;

/// Heading config
$heading-color: var(--color-primary);
$heading-font: var(--font-family-2nd);

/// Breakpoints
$breakpoints: (
    'tn': 20em,         // 320px
    'xss': 30em,        // 480px
    'xs': 35.5em,       // 568px
    'sms': 38.125em,    // 768px
    'sm': 48em,         // 768px
    'md': 62em,         // 992px
    'lg': 75em,         // 1200px
    'xl': 87.5em,       // 1400px
    'header': 90.62em,  // 1450px
    'xxl': 93.75em,     // 1500px
);

/// Google fonts config
///
/// List here all the google fonts that you want to use, following this pattern inside the $google-fonts array: 
///     -----------------------------------------------------------------
///     -> '[font-name]': ([weight-1], [weight-2], ...)
///     -----------------------------------------------------------------
///     -> [weight] is a number ===> Regular font
///     -> [weight] is a string ===> Italic font
///
/// !Important -> All font weight must be grouped and can't be mixed. 
///                 Regular fonts must be first and Italic must be last.
///                 Example: 'Poppins': (300, 400, 700, '400', '600')
///
$google-fonts: (
    'Karla': (300, 400, 500, 700, '300'),
);

/// External fonts config
$external-fonts: ('Luxerie Regular', 'Roustel Regular');

/// Spacings
///
/// Add here all the spacing that you want to use as and extend or with class helpers.
/// To add a spacing, follow this pattern inside the $spacings array::after
///     -----------------------------------------------------------------
///     -> [number]: ([css-abbreviation-1], [css-abbreviation-2], etc)
///     -----------------------------------------------------------------
///     [number]: The value that will be in the CSS property in "rem".
///     [css-abbreviation]: The abbreviation that will be use for the value.
///         -> Available abbreviations: 
///             ° Margin: 'm', 'mx', 'my', 'mt', 'mr', 'mb', 'ml'
///             ° Padding: 'p', 'px', 'py', 'pt', 'pr', 'pb', 'pl'
///     -----------------------------------------------------------------
///     -> Examples: 
///
///        ° 150: ('py', 'pb', 'ml')
///             In the example above, the [number] is a type of "Number", so we would be able to use:
///                 -> @extend %py-150 /// @extend %pb-150 /// @extend %ml-150
///
///        ° '150': ('py', 'pb', 'ml')
///             In the example above, the [number] is a type of "String", so we would be able to use:
///                 -> @extend %py-150 /// @extend %pb-150 /// @extend %ml-150
///                 -> .u-py-150 /// .u-pb-150 /// .u-ml-150
///
///     -----------------------------------------------------------------
///     
/// 
$spacings: (
    '0': ('mb'),
    '5': ('mb'),
    '10': ('mb','mt'),
    '15': ('ml'),
    '20': ('mb'),
    '30': ('mb'),
    '50': ('mb'),
    '70': ('mb'),
);

// Section spacing
$section-pad: 15rem 0;
$section-pad-sm: 5rem 0;

/// Other config
$animation-duration: 1s;
$transition: .4s;

/// Grid config
///
/// Note: These variables down below are set to be the default grid configuration.
/// You can feel free to generate an other grid with different setting with the appropriate mixin.
///
$grid-width-default: 150rem;  // 1500px
$grid-gap-default: 2.5rem;
$grid-gap-rows: 2rem;

$grid-rows: (
    '2c': 'g',
    '12': 'sm',
);

/// Uicons fonts config
///
/// $uicons-type: Choose the style of the icon that you want to use (Uicons: https://www.flaticon.com/uicons)
/// -> Available choices : 'bold-rounded', 'regular-rounded', 'regular-straight', 'solid-rounded', 'solid-straight'
/// -> Leave empty if you don't want to use uicons
///
/// $uicons-fonts: Add a list of all icons that you want to use directly in the HTML template
/// -> Tips: To see the list of all available icons, go to the ../base/_fonts.scss file and 
///     check the big commented line which list all the available icons.
///     When you find the icon that you want, just copy the line and paste this one into the SASS array "$uicons-fonts".
///     ° Example: I want to use an angle-down icon in the template. I juste have to copy the line "angle-down":"\f11a" and paste it in the sass array.
///                 So now in my SASS files, I can use the icon by adding an extend as below -> "@extend %fig-angle-down".
///                 Or in the template, I can use the icon by adding this element "<i class="fig-angle-down"></i>".
///
/// $uicons-brands: If you want to use the brands icons.
/// -> Leave empty if you don't want to use uicons-brands
///
/// $uicons-brands-html: Same as the $uicons-html but for the brands icons, 
///     except that the icons should begin with "fib" instead of "fig"
/// 

/// All general icons that can be used like below:
/// -> Extend: @extend fig-[icon-name]
/// -> Template: <i class="fig-[icon-name]"></i>
///
$uicons-type: 'regular-rounded';
$uicons-fonts: (
    "arrow-left":"\f14a",
    "arrow-right":"\f14b",
    "arrow-to-top":"\f157",
    "angle-left":"\f11b",
    "angle-right":"\f11c",
    "check":"\f1fb",
    "cross":"\f27c",
    "eye":"\f2ce",
    "eye-crossed":"\f2cc",
    "heart":"\f33d",
    "home":"\f346",
    "globe":"\f315",
    "leaf":"\f376",
    "loading":"\f389",
    "map-marker":"\f398",
    "minus-small":"\f3ad",
    "phone-call":"\f3eb",
    "plus-small":"\f401",
    "quote-right":"\f416",
    "shopping-cart":"\f46c",
    "shopping-cart-add":"\f46a",
    "tags":"\f4d0",
    "trash":"\f505",
    "user":"\f525",
    "user-add":"\f523",
);
/// All brands icons that can be used like below:
/// -> Extend: @extend fib-[icon-name]
/// -> Template: <i class="fib-[icon-name]"></i>
///
$uicons-brands: 'true';
$uicons-brands-fonts: (
    "facebook":"\f14f",
    "instagram":"\f169",
    "twitter":"\f1b7",
);

/// Utilities config
///
/// Variables down below are only here to make some CSS declarations easier to write and 
/// to make those one unify.
///
$themeName: "biokide";
$themePath: "/themes/" + $themeName;
$storage: "/storage";
